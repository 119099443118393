import React, { useState } from 'react';
import ClientService from '../api/services/Client';
import { Button, Autocomplete } from '@mui/material';
import Alert from '@mui/material/Alert';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useMutation, useQueryClient, useQuery } from 'react-query';
import TextField from '@mui/material/TextField';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { CircularLoading } from './CircularLoading';
import DocumentService from '../api/services/Document';

export function CopyAngeboteDialog(props) {
    const [open, setOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [inTransaction, setInTransaction] = useState(false);
    const [selectedClient, setSelectedClient] = useState('');

    const queryClient = useQueryClient();

    const {
        isError: isErrorClients,
        isLoading: isLoadingClients,
        error: errorClients,
        data: dataClients
    } = useQuery(['clientData'], async () => await ClientService.getMultiple(-1), {
        keepPreviousData: true,
        refetchOnWindowFocus: false,
        staleTime: 10000
    });

    const copyOfferMutation = useMutation(
        ({ document_id, client }) => {
            return DocumentService.copyDocumentForClient(document_id, client);
        },
        {
            onSuccess: () => {
                queryClient.invalidateQueries('documents');
                handleClose();
            },
            onError: (error) => {
                console.log(error.response);
                if (error.response) {
                    if (error.response.status === 400) {
                        setErrorMessage(error.response.status + ': Validation error: could not process');
                    } else {
                        setErrorMessage(error.response.status + ': ' + error.response.data.message);
                    }
                } else if (error.request) {
                    setErrorMessage('Server not reachable');
                    console.log(error.request);
                } else {
                    setErrorMessage('Undefined error, please contact your admin');
                    console.log('Error', error.message);
                }
            },
            onSettled: () => {
                setInTransaction(false);
            }
        }
    );

    const handleClose = () => {
        setSelectedClient('');
        setOpen(false);
        setErrorMessage('');
        setInTransaction(false);
    };

    if (isErrorClients) {
        setErrorMessage('Error: ', errorClients);
    }

    return (
        <span>
            <Button startIcon={<ContentCopyIcon />} size="small" onClick={() => setOpen(true)}>
                Kopieren
            </Button>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle sx={{ maxWidth: 500 }}>
                    Wähle den Kunden aus, für den das Angebot kopiert werden soll
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {errorMessage ? <Alert severity="error">{errorMessage}</Alert> : ''}
                    </DialogContentText>
                    {isLoadingClients ? (
                        <>Clients are loading..</>
                    ) : (
                        <Autocomplete
                            id={'client-id'}
                            options={dataClients ? dataClients.data : []}
                            getOptionLabel={(option) => String(option.name)}
                            onChange={(_event, newValue) => {
                                setSelectedClient(newValue);
                            }}
                            sx={{ mt: 2, ml: 0, mr: 1, maxWidth: 500 }}
                            size="small"
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    required
                                    placeholder={'Suche Kunden'}
                                    label="Kunde wählen"
                                    variant="standard"
                                />
                            )}
                        />
                    )}
                </DialogContent>
                {inTransaction ? (
                    <>
                        <CircularLoading width="40px" mr={4} mt={4} mb={2} /> Angebot wird kopiert...
                    </>
                ) : (
                    <DialogActions>
                        <Button onClick={handleClose}>Abbrechen</Button>
                        <Button
                            disabled={inTransaction || selectedClient === ''}
                            onClick={() => {
                                if (selectedClient === '') {
                                    setErrorMessage('Fehler: Es muss ein Kunde gewählt werden');
                                } else {
                                    setInTransaction(true);
                                    copyOfferMutation.mutate({ document_id: props.documentID, client: selectedClient });
                                }
                            }}>
                            Angebot kopieren
                        </Button>
                    </DialogActions>
                )}
            </Dialog>
        </span>
    );
}
