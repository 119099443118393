import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import { Alert, Button, Divider, Grid, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import { useTheme } from '@mui/material/styles';
import DOMPurify from 'isomorphic-dompurify';
import React from 'react';
import { useQuery } from 'react-query';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import PositionService from '../api/services/Position';
import { PageLayout } from '../components/PageLayout';
import PdfViewer from '../components/PdfViewer';
import Products from '../components/Products';

const EmailState = {
    queued: 1,
    sent: 2,
    received: 3
};

const displayDate = (time) => {
    // This displays the date in the format:
    // Mittwoch, 2. November 2022 um 12:57
    const date = new Date(time);
    return Intl.DateTimeFormat('de-DE', {
        dateStyle: 'full',
        timeStyle: 'short'
    }).format(date);
};

const getFileNameFromPath = (path) => path.split('\\').pop().split('/').pop();

function FileDisplay({ filePath }) {
    // Temporary component to display files. TODO: move and make reusable

    let content;
    let src = `/api/files/${filePath}`;
    if (filePath.endsWith('jpeg') || filePath.endsWith('jpg') || filePath.endsWith('png')) {
        content = <img src={src} />;
    } else if (filePath.endsWith('pdf')) {
        content = <PdfViewer url={src} />;
    }
    return (
        <div>
            <a href={src} download>
                {getFileNameFromPath(filePath)}
            </a>
            {content}
        </div>
    );
}

function EmailCard({ email }) {
    // Render the email view
    const [open, setOpen] = React.useState(false);
    return (
        <>
            <Divider />
            <Grid container>
                <Grid item xs={8}>
                    <h2>{email.subject}</h2>
                </Grid>
                <Grid item xs={4}>
                    <h4>{displayDate(email.created_at)}</h4>
                </Grid>
                <Grid item xs={12}>
                    <h4>From: {email.sender}</h4>
                </Grid>
                <Grid item xs={12}>
                    <div dangerouslySetInnerHTML={{ __html: email.body }}></div>
                </Grid>
                <Grid item style={{ marginBottom: 20, marginTop: 20 }} xs={12}>
                    {email.attachments.length} attachments
                    {email.attachments.length > 0 && (
                        <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                        </IconButton>
                    )}
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        {email.attachments.map((attachment) => (
                            <FileDisplay key={attachment.id} filePath={attachment.path} />
                        ))}
                    </Collapse>
                </Grid>
            </Grid>
        </>
    );
}

function OfferRequest({ request, theme }) {
    // Renders the conversation with one contact only.
    // Only show received emails for now.
    const receivedEmails = request.emails
        .filter((email) => email.state == EmailState.received)
        .sort((email) => email.created_at);
    return (
        <Grid container>
            <Grid item xs={12}>
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        alignContent: 'space-around'
                    }}>
                    <div
                        style={{
                            height: 2,
                            backgroundColor: theme.palette.fassbender.green,
                            flexGrow: 1
                        }}></div>
                    <Typography
                        variant="h5"
                        style={{
                            color: theme.palette.fassbender.green,
                            marginLeft: 5,
                            marginRight: 5,
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis'
                        }}>
                        {request.contact.provider.name}
                    </Typography>
                    <div
                        style={{
                            height: 2,
                            backgroundColor: theme.palette.fassbender.green,
                            flexGrow: 1
                        }}></div>
                </div>
            </Grid>
            <Grid item xs={12} sx={{ mt: 2 }}>
                {receivedEmails.length == 0
                    ? 'Bisher keine Antworten erhalten'
                    : receivedEmails.map((email) => <EmailCard key={email.id} email={email} />)}
            </Grid>
        </Grid>
    );
}

function OfferView({ documentID, positionID, navigate }) {
    const theme = useTheme();

    const { isLoading, isError, data } = useQuery(
        ['positionData', positionID],
        () => PositionService.getPaginatedPosition(documentID, positionID),
        {
            keepPreviousData: false,
            refetchOnWindowFocus: false,
            retry: false
        }
    );
    if (isError) {
        return (
            <PageLayout title="Fehler">
                <Box>
                    <Alert severity="error">Not Found</Alert>
                </Box>
            </PageLayout>
        );
    }

    return (
        <PageLayout title="Angebot bearbeiten">
            {!isLoading ? (
                <>
                    <Box sx={{ p: 3, pb: 0, pt: 1 }}>
                        <Button
                            variant="text"
                            size="large"
                            startIcon={<KeyboardDoubleArrowLeftIcon />}
                            onClick={() => navigate(`/angebote/${documentID}/`)}>
                            Zurück zur Angebotsbearbeitung
                        </Button>
                    </Box>
                    <Box
                        sx={{
                            flexGrow: 1,
                            display: 'flex',
                            pl: 3,
                            pr: 3,
                            justifyContent: 'space-between'
                        }}>
                        <Button
                            variant="text"
                            disabled={!data.data.pagination.has_prev}
                            color="success"
                            size="large"
                            onClick={() => navigate(`/angebote/${documentID}/${positionID - 1}`)}>
                            <ArrowBackIosIcon />
                            Vorherige Position
                        </Button>
                        <Button
                            variant="text"
                            disabled={!data.data.pagination.has_more}
                            color="success"
                            size="large"
                            onClick={() => navigate(`/angebote/${documentID}/${positionID + 1}`)}>
                            Nächste Position <ArrowForwardIosIcon />
                        </Button>
                    </Box>
                    <Box
                        component="main"
                        sx={{
                            flexGrow: 1,
                            py: 8,
                            padding: 5
                        }}>
                        <Grid container spacing={3}>
                            <Grid item xs={8}>
                                <Grid container spacing={2}>
                                    {data.data.position.provision_status !== 0 ? (
                                        <Grid item xs={12}>
                                            <Typography sx={{ fontStyle: 'italic' }}>
                                                **
                                                {data.data.position.provision_status === 2 ? (
                                                    <>Bedarfsposition mit GB</>
                                                ) : (
                                                    <>Bedarfsposition ohne GB</>
                                                )}
                                                **
                                            </Typography>
                                        </Grid>
                                    ) : null}{' '}
                                    <Grid item xs={2}>
                                        <Typography sx={{ fontWeight: 'bold' }}>
                                            Pos. {data.data.position.pos_nr}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <div
                                            dangerouslySetInnerHTML={{
                                                __html: DOMPurify.sanitize(data.data.position.outline_text)
                                            }}></div>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <div>
                                            {data.data.position.amount} {data.data.position.amount_type}
                                        </div>
                                    </Grid>
                                    <Grid item xs={12}>
                                        {data.data.position.requests.map((request, i) => (
                                            <OfferRequest
                                                key={request.id}
                                                index={i + 1}
                                                request={request}
                                                theme={theme}
                                            />
                                        ))}
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={4}>
                                <Box sx={{ position: 'sticky', top: 20 }}>
                                    <Products
                                        positionID={data.data.position.id}
                                        documentID={documentID}
                                        numCols={1}
                                        type="1"
                                        requested_amount={data.data.position.amount}
                                        requested_amount_type={data.data.position.amount_type}
                                    />
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </>
            ) : (
                'Loading'
            )}
        </PageLayout>
    );
}

export default function AnswerPage() {
    const { document, position } = useParams();
    const searchParams = useSearchParams()[0];
    const navigate = useNavigate();

    const isRelative = searchParams.get('isRelative') == 'false';
    if (isRelative) {
        // We don't know the rank of the position yet, so fetch that first.
        // Then navigate to the correct relative position.
        PositionService.getRankOfPosition(document, position).then((data) => {
            navigate(`/angebote/${document}/${data.data.rank}`, { replace: true });
        });
        return <PageLayout>Loading</PageLayout>;
    } else {
        return <OfferView documentID={parseInt(document)} positionID={parseInt(position)} navigate={navigate} />;
    }
}
