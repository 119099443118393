import API from '../axios.js';
import AuthUtils from '../auth/utils.js';

class ProductService {
    delete(productID) {
        return API({
            method: 'delete',
            url: '/product/' + encodeURIComponent(productID),
            headers: AuthUtils.authHeaders()
        });
    }

    get(productID) {
        return API({
            method: 'get',
            url: '/product/' + encodeURIComponent(productID),
            headers: AuthUtils.authHeaders()
        });
    }

    put(data) {
        let headers = AuthUtils.authHeaders();
        return API({ method: 'post', url: '/products', data: data, headers: headers });
    }

    patch(product) {
        return API({
            method: 'patch',
            url: '/product/' + encodeURIComponent(product.id),
            headers: AuthUtils.authHeaders(),
            data: {
                product
            }
        });
    }

    changeDB(positionIDs, vkPercentage) {
        return API({
            method: 'patch',
            url: '/products',
            headers: AuthUtils.authHeaders(),
            data: {
                position_ids: positionIDs,
                vk_percentage: vkPercentage
            }
        });
    }

    getProductsForPosition(positionID) {
        let headers = AuthUtils.authHeaders();
        return API({ method: 'get', url: '/products?positionID=' + encodeURIComponent(positionID), headers: headers });
    }
}

export default new ProductService();
