import { Pagination } from '@mui/material';
import * as PDFJS from 'pdfjs-dist';
import pdfjsWorker from 'pdfjs-dist/build/pdf.worker.entry';
import 'pdfjs-dist/web/pdf_viewer.css';
import React, { memo, useCallback, useEffect, useRef, useState } from 'react';

/**
 * This is the event emitter that is used to communicate between the PDF viewer,
 * and any other component that requires select text access to the PDF.
 */
export const PdfEventEmitter = {
    subscribers: [],
    dispatch(data) {
        if (this.subscribers.length === 0) return;
        this.subscribers.forEach((callback) => callback(data));
    },
    subscribe(callback) {
        this.subscribers.push(callback);
    },
    unsubscribe(callback) {
        this.subscribers.pop(callback);
    }
};

/**
 * This is the PDF viewer component. It is a wrapper around the PDF.js library.
 * It is memoized to prevent unnecessary re-renders. Only re-render when the internal
 * hooks are called, or the url has changed.
 * @param {string} url - The URL of the PDF to be displayed.
 */
const PdfViewer = memo(function PdfViewer({ url }) {
    const canvasRef = useRef();
    const textRef = useRef();
    const rectRef = useRef();
    PDFJS.GlobalWorkerOptions.workerSrc = pdfjsWorker;

    const [canvasSize, setCanvasSize] = useState(0);
    const [pdfRef, setPdfRef] = useState();
    const [currentPage, setCurrentPage] = useState(1);

    const renderPage = useCallback(
        async (pageNum, pdf = pdfRef) => {
            if (!pdf) {
                return;
            }
            let page = await pdf.getPage(pageNum);
            const canvas = canvasRef.current;
            const viewport = page.getViewport({
                scale: canvas.parentElement.offsetWidth / page.getViewport({ scale: 1 }).width
            });
            canvas.height = viewport.height;
            canvas.width = viewport.width;
            const renderContext = {
                canvasContext: canvas.getContext('2d'),
                viewport: viewport
            };
            await page.render(renderContext);
            let textContent = await page.getTextContent();
            var textLayer = textRef.current;
            textLayer.innerHTML = '';

            // Pass the data to the method for rendering of text over the pdf canvas.
            PDFJS.renderTextLayer({
                textContent: textContent,
                container: textLayer,
                viewport: viewport,
                textDivs: []
            });
        },
        [pdfRef, currentPage, canvasSize]
    );

    /**
     * This functions checks if 2 rectangles intersect.
     * @param {DOMRect} rect1 - The first rectangle.
     * @param {DOMRect} rect2 - The second rectangle.
     */
    const rectanglesIntersect = (rect1, rect2) => {
        let aLeftOfB = rect1.x + rect1.width < rect2.x;
        let aRightOfB = rect1.x > rect2.x + rect2.width;
        let aAboveB = rect1.y + rect1.height < rect2.y;
        let aBelowB = rect1.y > rect2.y + rect2.height;

        return !(aLeftOfB || aRightOfB || aAboveB || aBelowB);
    };

    /**
     * This is the effect that is called when the PDF is loaded. It is responsible for
     * dispatching select text events to the event emitter.
     */
    useEffect(() => {
        let altPressed = false;
        let startCoordinates = null;

        /**
         * Initialize the rectangle with the given top-left coordinates.
         * @param {float} x coordinate of top-left corner.
         * @param {float} y coordinate of top-left corner.
         */
        const initializeRectangle = (x, y) => {
            rectRef.current.style.left = x + 'px';
            rectRef.current.style.top = y + 'px';
            rectRef.current.style.display = 'block';
            startCoordinates = {
                x: x,
                y: y
            };
        };

        /**
         * Disable text selection on all span elements.
         */
        const disableTextSelection = () => {
            for (let textElem of textRef.current.children) {
                textElem.style['pointer-events'] = 'none';
            }
        };

        /**
         * Enable text selection on all span elements.
         */
        const enableTextSelection = () => {
            for (let textElem of textRef.current.children) {
                textElem.style['pointer-events'] = 'auto';
            }
        };

        /**
         * Cleanup the rectangle by setting it to 0 width and height, and hiding it.
         */
        const cleanupRectangle = () => {
            startCoordinates = null;
            rectRef.current.style.width = '0px';
            rectRef.current.style.height = '0px';
            rectRef.current.style.display = 'none';
        };

        /**
         * Parse the final selection and dispatch it to the event emitter.
         */
        const handleSelect = () => {
            let selection = document.getSelection();

            // Rectangular selection.
            if (altPressed && startCoordinates !== null) {
                let selectedElements = [];
                let drawnRectangle = rectRef.current.getBoundingClientRect();
                cleanupRectangle();
                enableTextSelection();

                for (let textElem of textRef.current.children) {
                    if (rectanglesIntersect(drawnRectangle, textElem.getBoundingClientRect())) {
                        selectedElements.push(textElem);
                    }
                }
                if (selectedElements.length > 0) {
                    // Join all selected spans with newline.
                    PdfEventEmitter.dispatch(selectedElements.map((elem) => elem.textContent).join('\n'));
                }
                // Text selection.
            } else if (textRef.current.contains(selection.anchorNode) && selection.toString().length > 0) {
                PdfEventEmitter.dispatch(selection.toString());
            }
        };

        // Add event listeners for mouse and keyboard events.
        textRef.current.addEventListener('mouseup', handleSelect);

        const handlemMouseDown = (e) => {
            if (altPressed) {
                initializeRectangle(e.offsetX, e.offsetY);
            }
        };

        textRef.current.addEventListener('mousedown', handlemMouseDown);

        textRef.current.addEventListener('mousemove', (e) => {
            if (altPressed && startCoordinates !== null) {
                rectRef.current.style.width = e.offsetX - startCoordinates.x + 'px';
                rectRef.current.style.height = e.offsetY - startCoordinates.y + 'px';
            }
        });

        document.addEventListener('keydown', (e) => {
            if (e.key === 'Alt') {
                altPressed = true;
                disableTextSelection();
            }
        });

        document.addEventListener('keyup', (e) => {
            if (e.key === 'Alt') {
                altPressed = false;
                cleanupRectangle();
                enableTextSelection();
            }
        });

        // Disable rectangle selection when the window loses focus.
        window.addEventListener('focus', () => {
            altPressed = false;
            cleanupRectangle();
            enableTextSelection();
        });

        return () => {
            textRef.current?.removeEventListener('mouseup', handleSelect);
            textRef.current?.removeEventListener('mousedown', handlemMouseDown);
        };
    }, []);

    /**
     * This is the effect that is called when the PDF is loaded. It is responsible for
     * handling the resize event, and re-rendering the PDF and text layers.
     */
    useEffect(() => {
        async function handleResize() {
            setCanvasSize(canvasRef.current.parentElement.offsetWidth);
        }

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [canvasRef]);

    /**
     * This is to render the first PdfPage when the PDF is loaded.
     */
    useEffect(() => {
        renderPage(currentPage, pdfRef, canvasRef);
    }, [pdfRef, currentPage, renderPage]);

    /**
     * This is the effect to load the PDF from the given URL.
     * After the PDF is loaded, it is set to the pdfRef state.
     */
    useEffect(() => {
        const loadingTask = PDFJS.getDocument(url);
        loadingTask.promise.then(
            (loadedPdf) => {
                setPdfRef(loadedPdf);
            },
            function (reason) {
                console.error(reason);
            }
        );
    }, [url]);

    /**
     * Handle the page change event.
     * @param {Event} event the event emitted by the pagination component.
     * @param {int} selectedPage the page number selected.
     */
    const onPageSelect = (event, selectedPage) => {
        setCurrentPage(selectedPage);
    };

    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Pagination count={pdfRef?.numPages} onChange={onPageSelect} />
            <div style={{ position: 'relative', width: '100%' }}>
                <canvas style={{ width: '100%' }} ref={canvasRef}></canvas>
                <div className="textLayer" style={{ width: '100%' }} ref={textRef} id="text-layer"></div>
                <div
                    style={{
                        position: 'absolute',
                        pointerEvents: 'none',
                        background: 'rgba(237, 28, 36, 0.24)',
                        border: '2px dashed rgba(237, 28, 36, 0.8)',
                        borderRadius: '2px',
                        display: 'none'
                    }}
                    ref={rectRef}></div>
            </div>
        </div>
    );
});

export default PdfViewer;
