import CancelIcon from '@mui/icons-material/Cancel';
import DeleteIcon from '@mui/icons-material/Delete';
import { Alert, Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import React, { useState } from 'react';
import { useMutation } from 'react-query';
import { CircularLoading } from '../components/CircularLoading';
// import ManageHistoryIcon from '@mui/icons-material/ManageHistory';
import 'moment/locale/de';

export function DeleteConfirmation({
    // entity should be an object with at least id and name
    entity,
    onSuccess,
    mutationFunction,
    confirmationTitle,
    confirmationMessage,
    loadingMessage,
    showDeleteConfirmation,
    errorMessage,
    setErrorMessage,
    setShowDeleteConfirmation
}) {
    const [inTransaction, setInTransaction] = useState(false);
    const theme = useTheme();

    const mutation = useMutation(mutationFunction, {
        onSuccess: onSuccess,
        onError: (error) => {
            if (error.response) {
                if (error.response.status === 400) {
                    setErrorMessage(error.response.status + ': Validation error: could not process');
                } else {
                    setErrorMessage(error.response.status + ': ' + error.response.data.message);
                }
            } else if (error.request) {
                setErrorMessage('Server not reachable');
            } else {
                setErrorMessage('Undefined error, please contact your admin');
                console.log('Error', error.message);
            }
            setTimeout(function () {
                setErrorMessage('');
            }, 10000);
        },
        onSettled: () => {
            setShowDeleteConfirmation(false);
            setInTransaction(false);
        }
    });

    if (errorMessage !== '') {
        return <Alert severity="error">{errorMessage}</Alert>;
    }

    if (inTransaction) {
        return (
            <Grid container key={entity.id}>
                <Grid item xs={12} sx={{ mt: 2, mb: 2 }}>
                    <Divider />
                </Grid>{' '}
                <Grid
                    item
                    xs={12}
                    sx={{
                        display: 'flex',
                        p: 2,
                        color: theme.palette.error.dark
                    }}
                    alignItems="center">
                    <CircularLoading width="1.5rem" mr={2} />
                    {entity.name} löschen...
                </Grid>
                <Divider />
            </Grid>
        );
    }
    return (
        <Dialog open={showDeleteConfirmation} onClose={() => setShowDeleteConfirmation(false)}>
            <DialogTitle> {confirmationTitle} </DialogTitle>
            <DialogContent>{confirmationMessage}</DialogContent>
            <DialogActions>
                <Button
                    startIcon={<CancelIcon />}
                    disabled={inTransaction}
                    sx={{ color: 'grey' }}
                    onClick={() => setShowDeleteConfirmation(false)}>
                    Abbrechen
                </Button>
                <Button
                    startIcon={<DeleteIcon />}
                    disabled={inTransaction}
                    color="error"
                    onClick={() => {
                        setInTransaction(true);
                        mutation.mutate();
                    }}>
                    Löschen
                </Button>
            </DialogActions>
            {inTransaction ? (
                <Grid container>
                    <Grid alignItems="center" sx={{ display: 'flex', color: 'neutral.700' }}>
                        <CircularLoading width="1.5rem" mr={1} /> <div>{{ loadingMessage }}...</div>
                    </Grid>
                </Grid>
            ) : null}
        </Dialog>
    );
}
