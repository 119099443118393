import AuthUtils from '../auth/utils.js';
import API from '../axios.js';

class ProviderService {
    delete(providerID) {
        return API({
            method: 'delete',
            url: '/provider/' + encodeURIComponent(providerID),
            headers: AuthUtils.authHeaders()
        });
    }

    put(data) {
        let headers = AuthUtils.authHeaders();
        return API({ method: 'post', url: '/providers', data: data, headers: headers });
    }

    putMultiple(formData) {
        let headers = AuthUtils.authHeaders();
        return API({ method: 'post', url: '/providers', data: formData, headers: headers });
    }

    get(providerID) {
        return API({
            method: 'get',
            url: '/provider/' + encodeURIComponent(providerID),
            headers: AuthUtils.authHeaders()
        });
    }

    search(filter, page = 1, includeContacts = false) {
        return API({
            method: 'get',
            url:
                '/providers/search?page=' +
                encodeURIComponent(page) +
                '&filter=' +
                encodeURIComponent(filter) +
                '&includeContacts=' +
                encodeURIComponent(includeContacts),
            headers: AuthUtils.authHeaders()
        });
    }

    getMultiple(page = 1, includeContacts = false) {
        return API({
            method: 'get',
            url:
                '/providers?page=' +
                encodeURIComponent(page) +
                '&includeContacts=' +
                encodeURIComponent(includeContacts),
            headers: AuthUtils.authHeaders()
        });
    }
}

export default new ProviderService();
