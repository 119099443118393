import AuthUtils from '../auth/utils.js';
import API from '../axios.js';

class ProductGroupService {
    patch(product_group_id) {
        return API({
            method: 'patch',
            url: '/product_group/' + encodeURIComponent(product_group_id),
            headers: AuthUtils.authHeaders()
        });
    }

    copyProductsForPosition(data) {
        return API({
            method: 'post',
            url: '/product_groups',
            headers: AuthUtils.authHeaders(),
            data: data
        });
    }
}

export default new ProductGroupService();
