import { Autocomplete, Button, Tab, Tabs } from '@mui/material';
import Alert from '@mui/material/Alert';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import React, { useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import ContactService from '../api/services/Contact';
import ProviderService from '../api/services/Provider';
import { validateEmail } from '../utils/Validations';
import { CircularLoading } from './CircularLoading';

export default function RegisterDialog(props) {
    const [providerName, setProviderName] = useState('');
    const [provider, setProvider] = useState(null);
    const [contactEmail, setContactEmail] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [inTransaction, setInTransaction] = useState(false);
    const [tabValue, setTabValue] = useState('select');

    const queryClient = useQueryClient();

    const handleClose = () => {
        setProviderName('');
        setProvider(null);
        setContactEmail('');
        props.setOpen(false);
        setErrorMessage('');
        setInTransaction(false);
    };

    const combinedMutation = useMutation(
        async () => {
            let providerToAssociate;
            if (tabValue === 'new') {
                let response = await ProviderService.put({ name: providerName });
                providerToAssociate = response.data;
            } else {
                providerToAssociate = provider;
            }
            return ContactService.put({ email: contactEmail, provider_id: providerToAssociate.id });
        },
        {
            onSuccess: async (response) => {
                await queryClient.invalidateQueries('providerData');
                props.addContact(response.data.id);
                handleClose();
            },
            onError: (error) => {
                console.log(error.response);
                if (error.response) {
                    if (error.response.status === 400) {
                        setErrorMessage(error.response.status + ': Validation error: could not process');
                    } else {
                        setErrorMessage(error.response.status + ': ' + error.response.data.message);
                    }
                } else if (error.request) {
                    setErrorMessage('Server not reachable');
                    console.log(error.request);
                } else {
                    setErrorMessage('Undefined error, please contact your admin');
                    console.log('Error', error.message);
                }
            },
            onSettled: () => {
                setInTransaction(false);
            }
        }
    );

    return (
        <span>
            <Dialog open={props.open} onClose={handleClose} fullWidth maxWidth="sm">
                <DialogTitle>Füge einen neuen Lieferant & Kontakt hinzu</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {errorMessage ? <Alert severity="error">{errorMessage}</Alert> : ''}
                    </DialogContentText>
                    <Tabs
                        value={tabValue}
                        onChange={(_, value) => {
                            setTabValue(value);
                        }}
                        aria-label="basic tabs example">
                        <Tab value="select" label="Bestehenden Lieferanten wählen" />
                        <Tab value="new" label="Neuen Lieferanten anlegen" />
                    </Tabs>

                    {tabValue === 'select' ? (
                        <Autocomplete
                            options={props.allProviders}
                            getOptionLabel={(provider) => provider.name}
                            renderOption={(localProps, provider) => {
                                localProps.key = provider.id;
                                return <li {...localProps}>{provider.name}</li>;
                            }}
                            onChange={(_event, newValue) => {
                                setProvider(newValue);
                            }}
                            sx={{ m: 1 }}
                            renderInput={(params) => {
                                return (
                                    <TextField
                                        {...params}
                                        margin="normal"
                                        fullWidth
                                        required
                                        placeholder={'Suche Firmen'}
                                        label="Firma wählen"
                                        variant="standard"
                                    />
                                );
                            }}
                        />
                    ) : (
                        <TextField
                            required
                            id="new-provider"
                            label="Firmenname"
                            margin="normal"
                            fullWidth
                            name="new-provider"
                            autoComplete="off"
                            autoFocus
                            value={providerName}
                            onChange={(e) => setProviderName(e.target.value)}
                        />
                    )}

                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="new-contact"
                        label="E-Mail-Adresse"
                        name="new-contact"
                        autoComplete="off"
                        autoFocus
                        error={contactEmail !== '' && !validateEmail(contactEmail)}
                        helperText={validateEmail(contactEmail) || contactEmail === '' ? '' : 'Email ungültig!'}
                        type="email"
                        value={contactEmail}
                        onChange={(e) => setContactEmail(e.target.value)}
                    />
                    {inTransaction ? (
                        <>
                            <CircularLoading width="40px" mr="4" /> Lieferant wird hinzugefügt...
                        </>
                    ) : null}
                </DialogContent>

                <DialogActions>
                    <Button onClick={handleClose}>Abbrechen</Button>
                    <Button
                        disabled={
                            inTransaction ||
                            !validateEmail(contactEmail) ||
                            (tabValue === 'new' && providerName === '') ||
                            (tabValue === 'select' && provider === null)
                        }
                        onClick={() => {
                            setInTransaction(true);
                            combinedMutation.mutate();
                        }}>
                        {tabValue == 'new' ? 'Lieferant & Kontakt hinzufügen' : 'Kontakt hinzufügen'}
                    </Button>
                </DialogActions>
            </Dialog>
        </span>
    );
}
