import React, { useState } from 'react';
import ProviderService from '../api/services/Provider';
import { Button, Grid, Snackbar } from '@mui/material';
import Alert from '@mui/material/Alert';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import InputLabel from '@mui/material/InputLabel';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { useQueryClient } from 'react-query';
import { CircularLoading } from './CircularLoading';

const generateSuccessMessage = (data) => {
    let vals = [];
    vals.push(data.providers_added + ' Lieferanten hinzugefügt');
    vals.push(data.providers_updated + ' Lieferanten aktualisiert');
    vals.push(data.contacts_added + ' Kontakte hinzugefügt');

    return vals.join(', ');
};

export default function ImportDialog() {
    const [selectedFile, setSelectedFile] = useState();
    const [open, setOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [successSnackbarOpen, setSuccessSnackbarOpen] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [inTransaction, setInTransaction] = useState(false);

    const queryClient = useQueryClient();

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleSnackbarClose = () => {
        setSuccessSnackbarOpen(false);
        setSuccessMessage('');
    };

    const handleClose = () => {
        setSelectedFile(null);
        setOpen(false);
        setErrorMessage(null);
        setInTransaction(false);
    };

    const handleSubmission = () => {
        setInTransaction(true);
        const formData = new FormData();

        formData.append('file', selectedFile);

        return ProviderService.put(formData)
            .then((response) => {
                setSuccessMessage(generateSuccessMessage(response.data));
                setSuccessSnackbarOpen(true);
                queryClient.invalidateQueries('providers');
                response.data.providers_contacts_updated.forEach((id) => {
                    queryClient.invalidateQueries(['contacts', id]);
                });
                handleClose();
            })
            .catch((error) => {
                if (error.response) {
                    if (error.response.status === 400) {
                        setErrorMessage(error.response.status + ': Validation error: could not process');
                    } else {
                        setErrorMessage(error.response.status + ': The server can not process this request');
                    }
                } else if (error.request) {
                    setErrorMessage('Server not reachable');
                    console.log(error.request);
                } else {
                    setErrorMessage('Undefined error, please contact your admin');
                    console.log('Error', error.message);
                }
            })
            .finally(() => {
                setInTransaction(false);
            });
    };

    return (
        <span>
            <Snackbar open={successSnackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose}>
                <Alert severity="success">{successMessage}</Alert>
            </Snackbar>
            <Button variant="outlined" onClick={handleClickOpen} startIcon={<UploadFileIcon />}>
                Kreditoren importieren
            </Button>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Kreditoren importieren</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {errorMessage ? <Alert severity="error">{errorMessage}</Alert> : ''}
                        Wähle die GWS-Export Datei aus, welche Kreditoren und Kontakte enthält.
                    </DialogContentText>
                    <InputLabel id={'file-select-label'} sx={{ mt: 2 }}>
                        Datei-Upload
                    </InputLabel>
                    <input
                        accept=".xlsx"
                        style={{ display: 'none' }}
                        id="raised-button-file"
                        onChange={(event) => setSelectedFile(event.target.files[0])}
                        multiple
                        type="file"
                    />
                    <label htmlFor="raised-button-file">
                        <Button variant="contained" component="span" disabled={inTransaction}>
                            Auswählen
                        </Button>
                        {selectedFile ? <Button>Ausgewählt: {selectedFile.name}</Button> : ''}
                    </label>
                    {inTransaction ? (
                        <Grid container sx={{ mt: 2, mb: 2 }}>
                            <Grid alignItems="center" sx={{ display: 'flex' }}>
                                <CircularLoading width="2rem" sx={{ mr: 2 }} /> Lieferanten werden importiert...
                            </Grid>
                        </Grid>
                    ) : null}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} disabled={inTransaction}>
                        Abbrechen
                    </Button>
                    <Button onClick={handleSubmission} disabled={inTransaction}>
                        Import starten
                    </Button>
                </DialogActions>
            </Dialog>
        </span>
    );
}
