import React from 'react';

import { Box } from '@mui/material';

import FassbenderIcon from '../FassbenderIcon.svg';

export const CircularLoading = ({ variant, ...other }) => {
    var styles = {};

    if (variant === 'absolute') {
        styles = {
            position: 'absolute',
            left: '50%',
            top: '50%'
        };
    } else {
        styles = other;
        styles.position = 'relative';
    }

    return (
        <Box sx={styles}>
            <img
                src={FassbenderIcon}
                width={variant === 'absolute' ? '80px' : '100%'}
                className="fassbender-loading"
                alt="Loading spinner"
                style={{
                    position: 'absolute',
                    maxWidth: variant === 'absolute' ? 'auto' : '100%',
                    height: 'auto',
                    top: 0,
                    bottom: 0,
                    left: 0,
                    right: 0,
                    margin: 'auto'
                }}
            />
        </Box>
    );
};
