import DeleteIcon from '@mui/icons-material/Delete';
import {
    Alert,
    Box,
    Button,
    Container,
    Grid,
    Table,
    TableBody,
    TableCell,
    TableFooter,
    TableHead,
    TableRow,
    TablePagination
} from '@mui/material';
import React, { useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import UserService from '../api/services/User';
import { PageLayout } from '../components/PageLayout';
import RegisterDialog from '../components/RegisterDialog';
import { Link } from 'react-router-dom';
import { CircularLoading } from '../components/CircularLoading';
import { useTheme } from '@mui/material/styles';

function UserRow({ user }) {
    const [isDeleting, setDeleting] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const theme = useTheme();

    const queryClient = useQueryClient();

    const mutation = useMutation(
        (userID) => {
            return UserService.delete(userID);
        },
        {
            onSuccess: () => {
                // TODO: use result of mutation directly
                queryClient.invalidateQueries('users');
            },
            onError: (error) => {
                if (error.response) {
                    if (error.response.status === 400) {
                        setErrorMessage(error.response.status + ': Validation error: could not process');
                    } else {
                        setErrorMessage(error.response.status + ': ' + error.response.data.message);
                    }
                } else if (error.request) {
                    setErrorMessage('Server not reachable');
                    console.log(error.request);
                } else {
                    setErrorMessage('Undefined error, please contact your admin');
                    console.log('Error', error.message);
                }
                setTimeout(function () {
                    setErrorMessage('');
                }, 10000);
            },
            onSettled: () => {
                setDeleting(false);
            }
        }
    );

    if (errorMessage !== '') {
        return <Alert severity="error">{errorMessage}</Alert>;
    }

    if (isDeleting) {
        return (
            <TableRow>
                <TableCell colSpan={2}>
                    <Grid alignItems="center" sx={{ display: 'flex', color: theme.palette.error.dark }}>
                        <CircularLoading width="1.5rem" mr={2} /> {user.name} löschen...
                    </Grid>
                </TableCell>
            </TableRow>
        );
    }

    return (
        <TableRow>
            <TableCell>
                <Link to={'/users/' + user.public_id}>{user.name}</Link>
            </TableCell>
            <TableCell>
                <Button
                    variant="contained"
                    size="small"
                    color="error"
                    startIcon={<DeleteIcon fontSize="small" />}
                    onClick={() => {
                        setDeleting(true);
                        mutation.mutate(user.public_id);
                    }}
                    sx={{ marginRight: '5px' }}>
                    Löschen
                </Button>
            </TableCell>
        </TableRow>
    );
}

export default function UsersPage() {
    const [page, setPage] = useState(0);

    const { isLoading, isError, error, data } = useQuery(['users', page], () => UserService.getMultiple(page), {
        keepPreviousData: true
    });

    return (
        <PageLayout title="NutzerInnen bearbeiten">
            <Box
                component="main"
                sx={{
                    flexGrow: 1,
                    py: 8
                }}>
                <Container maxWidth={false}>
                    <Box sx={{ mt: 3 }}>
                        <div>
                            {isLoading ? (
                                <CircularLoading variant="absolute" />
                            ) : isError ? (
                                <Alert severity="error">{error.message}</Alert>
                            ) : data.data.users.length ? (
                                <>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Name</TableCell>
                                                <TableCell>Actions</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {data.data.users.map(function (user) {
                                                return <UserRow key={user.public_id} user={user} />;
                                            })}
                                        </TableBody>
                                        {
                                            // eslint-disable-next-line max-len
                                            data.data.pagination.num_total > data.data.pagination.num_rows_per_page ? (
                                                <TableFooter>
                                                    <TableRow>
                                                        <TablePagination
                                                            count={data.data.pagination.num_total}
                                                            page={page}
                                                            rowsPerPage={data.data.pagination.num_rows_per_page}
                                                            rowsPerPageOptions={[]}
                                                            onPageChange={(event, newPage) => setPage(newPage)}
                                                        />
                                                    </TableRow>
                                                </TableFooter>
                                            ) : null
                                        }
                                    </Table>
                                </>
                            ) : (
                                <div>
                                    <p>No users here</p>
                                </div>
                            )}
                            <RegisterDialog />
                        </div>
                    </Box>
                </Container>
            </Box>
        </PageLayout>
    );
}
