import AuthUtils from '../auth/utils.js';
import API from '../axios.js';

class ContactService {
    delete(contactID) {
        return API({
            method: 'delete',
            url: '/contact/' + encodeURIComponent(contactID),
            headers: AuthUtils.authHeaders()
        });
    }

    put(data) {
        let headers = AuthUtils.authHeaders();
        return API({ method: 'post', url: '/contacts', data: data, headers: headers });
    }

    getContactsForProvider(providerID) {
        let headers = AuthUtils.authHeaders();
        return API({ method: 'get', url: '/contacts?providerID=' + encodeURIComponent(providerID), headers: headers });
    }

    patch(contactId, data) {
        let headers = AuthUtils.authHeaders();
        return API({ method: 'patch', url: '/contact/' + encodeURIComponent(contactId), data: data, headers: headers });
    }
}

export default new ContactService();
