import AuthUtils from '../auth/utils.js';
import API from '../axios.js';

class PositionService {
    get(positionID) {
        return API({
            method: 'get',
            url: '/position/' + encodeURIComponent(positionID),
            headers: AuthUtils.authHeaders()
        });
    }

    patch(positionID, type, contacts_to_request) {
        return API({
            method: 'patch',
            url: '/position/' + encodeURIComponent(positionID),
            headers: AuthUtils.authHeaders(),
            data: {
                type: type,
                contacts_to_request: contacts_to_request
            }
        });
    }

    getMultiple(documentID, typesToInclude = '-1') {
        return API({
            method: 'get',
            url:
                '/positions?documentID=' +
                encodeURIComponent(documentID) +
                '&includeTypes=' +
                encodeURIComponent(typesToInclude),
            headers: AuthUtils.authHeaders()
        });
    }

    getRankOfPosition(documentID, positionID) {
        return API({
            method: 'get',
            url: '/offer/rank/' + encodeURIComponent(documentID) + '/' + encodeURIComponent(positionID),
            headers: AuthUtils.authHeaders()
        });
    }

    getPaginatedPosition(documentID, relativePositionID) {
        return API({
            method: 'get',
            url: '/offer/' + encodeURIComponent(documentID) + '/' + encodeURIComponent(relativePositionID),
            headers: AuthUtils.authHeaders()
        });
    }
}

export default new PositionService();
