import FactoryIcon from '@mui/icons-material/Factory';
import InboxIcon from '@mui/icons-material/Inbox';
import LocalFloristIcon from '@mui/icons-material/LocalFlorist';
import OutboxIcon from '@mui/icons-material/Outbox';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import { Box, Divider, Drawer, List, ListItem, ListItemIcon, ListItemText, useMediaQuery } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import FassbenderLogo from '../logo-fassbender.svg';

const mainItems = [
    { href: '/eingang', title: 'Eingang', icon: <InboxIcon /> },
    { href: '/angebote', title: 'Angebote', icon: <OutboxIcon /> }
];

const adminItems = [
    { href: '/lieferanten', title: 'Lieferanten', icon: <FactoryIcon /> },
    { href: '/users', title: 'Nutzer', icon: <SupervisedUserCircleIcon /> },
    { href: '/bauunternehmer', title: 'Bauunternehmer', icon: <LocalFloristIcon /> }
];

const NavItem = ({ item }) => {
    const { pathname } = useLocation();
    const active = item.href === pathname;
    const pathStubs = pathname.split('/');
    const activeSub = item.href ? pathStubs[1] === item.href.substring(1) : false;

    return (
        <ListItem
            component={Link}
            to={item.href}
            sx={{
                backgroundColor: (active || activeSub) && 'rgba(255,255,255, 0.08)',
                '&:hover, &:focus': {
                    bgcolor: 'neutral.800'
                },
                color: active || activeSub ? 'secondary.main' : 'neutral.300'
            }}>
            <ListItemIcon>{item.icon}</ListItemIcon>
            <ListItemText primary={item.title} primaryTypographyProps={{ fontSize: 15 }}></ListItemText>
        </ListItem>
    );
};

export const PageSidebar = (props) => {
    const { open, onClose } = props;

    const lgUp = useMediaQuery((theme) => theme.breakpoints.up('lg'), {
        defaultMatches: true,
        noSsr: false
    });

    const content = (
        <>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%'
                }}>
                <div>
                    <Box sx={{ p: 3 }}>
                        <Link to="/eingang">
                            <div
                                style={{
                                    background: 'white',
                                    borderRadius: '3px',
                                    padding: '5px 5px 3px 5px',
                                    textAlign: 'center'
                                }}>
                                <img src={FassbenderLogo} className="App-logo" alt="Logo von Fassbender-Tenten" />
                            </div>
                        </Link>
                    </Box>
                </div>
                <Divider
                    sx={{
                        borderColor: '#2D3748'
                    }}
                />
                <Box>
                    <List component="nav" disablePadding>
                        {mainItems.map((item, idx) => {
                            return <NavItem key={idx} item={item} />;
                        })}
                    </List>
                </Box>
                <Box sx={{ mt: 2 }}>
                    <List>
                        <Divider sx={{ borderColor: '#2D3748' }} />
                        <ListItem>
                            <ListItemText
                                primary="Admin"
                                primaryTypographyProps={{
                                    fontSize: 12,
                                    color: 'neutral.400',
                                    textTransform: 'uppercase'
                                }}></ListItemText>
                        </ListItem>
                        {adminItems.map((item, idx) => {
                            return <NavItem key={idx} item={item} />;
                        })}
                    </List>
                </Box>
                <div
                    style={{
                        marginTop: 'auto',
                        marginLeft: '10px',
                        marginBottom: '10px',
                        fontSize: '10px',
                        color: '#AAA'
                    }}>
                    Version:{' '}
                    {
                        process.env.REACT_APP_WEBSITE_VERSION // eslint-disable-line no-undef
                    }
                </div>
            </Box>
        </>
    );

    if (lgUp) {
        return (
            <Drawer
                anchor="left"
                open
                PaperProps={{
                    sx: {
                        backgroundColor: 'neutral.900',
                        color: '#FFFFFF',
                        width: 280
                    }
                }}
                variant="permanent">
                {content}
            </Drawer>
        );
    }

    return (
        <Drawer
            anchor="left"
            onClose={onClose}
            open={open}
            PaperProps={{
                sx: {
                    backgroundColor: 'neutral.900',
                    color: '#FFFFFF',
                    width: 280
                }
            }}
            sx={{ zIndex: (theme) => theme.zIndex.appBar + 100 }}
            variant="temporary">
            {content}
        </Drawer>
    );
};

PageSidebar.propTypes = {
    onClose: PropTypes.func,
    open: PropTypes.bool
};
