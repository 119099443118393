import { useTheme } from '@emotion/react';
import AddIcon from '@mui/icons-material/Add';
import CreateIcon from '@mui/icons-material/Create';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Alert,
    Avatar,
    Box,
    Button,
    CircularProgress,
    Collapse,
    Divider,
    FormControl,
    FormControlLabel,
    FormGroup,
    Grid,
    InputLabel,
    ListItemIcon,
    MenuItem,
    MenuList,
    Select,
    Snackbar,
    SnackbarContent,
    Switch,
    TextField,
    Typography
} from '@mui/material';
import DOMPurify from 'isomorphic-dompurify';
import React, { useEffect, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import DocumentService from '../api/services/Document.js';
import PositionService from '../api/services/Position.js';
import ProductService from '../api/services/Product.js';
import ProductGroupService from '../api/services/ProductGroup.js';
import ProviderService from '../api/services/Provider.js';
import { CircularLoading } from '../components/CircularLoading.js';
import DocumentMeta from '../components/DocumentMeta';
import { PageLayout } from '../components/PageLayout';
import Position, { SupplierInput } from '../components/Position';
import { recursivelyFindChildren, replacePositions } from '../utils/HierarchyUtils';

function HierarchyHeading({ hierarchyData, level }) {
    const [showRemarks, setShowRemarks] = useState(false);

    return (
        <>
            <Grid container sx={{ mt: 1, mb: 1 }} justify="center">
                <Grid item xs={10} sx={{ pl: 2, alignItems: 'center', display: 'flex' }}>
                    {hierarchyData.id !== '' ? (
                        <Box sx={{ mr: 3, display: 'flex', alignItems: 'center' }}>
                            <Avatar sx={{ bgcolor: 'fassbender.green', mr: 1, width: 24, height: 24 }}>
                                <RadioButtonUncheckedIcon fontSize="small" />
                            </Avatar>
                            <Typography variant={'h' + (level + 3)}>{hierarchyData.id}</Typography>
                        </Box>
                    ) : null}
                    <Typography variant={'h' + (level + 3)}>{hierarchyData.heading}</Typography>
                </Grid>
                <Grid item xs={2}>
                    {hierarchyData.remarks.length > 0 ? (
                        <FormGroup>
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={showRemarks}
                                        onClick={(event) => setShowRemarks(event.target.checked)}
                                        inputProps={{ 'aria-label': 'controlled' }}
                                    />
                                }
                                label="Bemerkungen"
                            />
                        </FormGroup>
                    ) : null}
                </Grid>
                <Grid item xs={1} />
                <Grid item xs={11}>
                    <Collapse in={showRemarks}>
                        {hierarchyData.remarks.map((remark, idx) => {
                            return (
                                <Accordion disableGutters square key={idx} sx={{ fontSize: '0.8em' }}>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                        <Typography sx={{ fontSize: 14, fontWeight: 'bold' }}>
                                            {remark.outline_text !== '' ? remark.outline_text : 'Anmerkung'}
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <div
                                            dangerouslySetInnerHTML={{
                                                __html: DOMPurify.sanitize(remark.detailed_text)
                                            }}></div>
                                    </AccordionDetails>
                                </Accordion>
                            );
                        })}
                    </Collapse>
                </Grid>
            </Grid>
            <Divider />
        </>
    );
}

function extractPositionIDTitle(positions) {
    const positionIDs = [];
    if (positions.hasOwnProperty('children')) {
        const childKeys = Object.keys(positions.children);
        for (var idx = 0; idx < childKeys.length; idx++) {
            positionIDs.push(...extractPositionIDTitle(positions.children[childKeys[idx]]));
        }
    }
    for (var jdx = 0; jdx < positions.positions.length; jdx++) {
        positionIDs.push({
            id: positions.positions[jdx].id,
            title: positions.positions[jdx].pos_nr + ': ' + positions.positions[jdx].outline_text
        });
    }
    return positionIDs;
}

class RecursiveHierarchicalPositionDisplay extends React.Component {
    // eslint-disable-next-line
    shouldComponentUpdate(nextProps, nextState) {
        // Override default behaviour to compare hierarchiesToDisplay correctly.
        return !(
            this.props.hierarchy === nextProps.hierarchy &&
            this.props.level === nextProps.level &&
            this.props.editMode === nextProps.editMode &&
            this.props.contacts === nextProps.contacts &&
            this.props.documentViewType === nextProps.documentViewType &&
            JSON.stringify(this.props.selectedPositions) === JSON.stringify(nextProps.selectedPositions) &&
            this.props.documentID === nextProps.documentID &&
            this.props.documentState === nextProps.documentState &&
            Object.entries(this.props.hierarchiesToDisplay).sort().toString() ===
                Object.entries(nextProps.hierarchiesToDisplay).sort().toString() &&
            this.props.documentState > 1 &&
            JSON.stringify(this.props.positions) === JSON.stringify(nextProps.positions)
        );
        // Check to positions last to decrease delay
    }
    render() {
        const {
            hierarchy,
            positions,
            level,
            contacts,
            documentViewType,
            documentID,
            hierarchiesToDisplay,
            documentState,
            providers,
            selectPosition,
            unselectPosition,
            selectedPositions,
            editMode
        } = this.props;
        const findChildren = recursivelyFindChildren(hierarchy);
        const children = findChildren.map((childID) => hierarchiesToDisplay[childID]);
        if (!children.includes(true) || positions === undefined) return <></>;
        return (
            <>
                <HierarchyHeading hierarchyData={hierarchy} level={level} />
                {!hierarchy.hasOwnProperty('children') || Object.keys(hierarchy.children).length === 0
                    ? positions.positions.map((position, row_id) => {
                          return (
                              <Position
                                  positionID={position.id}
                                  edited={position.has_product}
                                  key={row_id}
                                  detailedTxt={position.detailed_text}
                                  outlineTxt={position.outline_text}
                                  stlz={position.standardleistungsnummer}
                                  type={position.type}
                                  amount_type={position.amount_type}
                                  amount={position.amount}
                                  pos_nr={position.pos_nr}
                                  provision_status={position.provision_status}
                                  contacts_to_request={position.contacts_to_request}
                                  contacts={contacts}
                                  documentState={documentState}
                                  providers={providers}
                                  documentViewType={documentViewType}
                                  documentID={documentID}
                                  selectPosition={selectPosition}
                                  unselectPosition={unselectPosition}
                                  selectedPositions={selectedPositions}
                                  editMode={editMode}
                              />
                          );
                      })
                    : Object.keys(hierarchy.children)
                          .sort((childKeyA, childKeyB) => parseInt(childKeyA) - parseInt(childKeyB))
                          .map((childKey) => {
                              const picked = Object.keys(hierarchiesToDisplay)
                                  .filter((key) =>
                                      key.startsWith(hierarchy.id === '' ? childKey : hierarchy.id + '.' + childKey)
                                  )
                                  .reduce((obj, key) => {
                                      obj[key] = hierarchiesToDisplay[key];
                                      return obj;
                                  }, {});
                              let subsetSelectedPositions = [];
                              if (
                                  positions.children[childKey] !== undefined &&
                                  positions.children[childKey].containedPositionIDs !== undefined
                              ) {
                                  subsetSelectedPositions = selectedPositions.filter((posID) =>
                                      positions.children[childKey].containedPositionIDs.includes(posID)
                                  );
                              }
                              return (
                                  <RecursiveHierarchicalPositionDisplay
                                      hierarchy={hierarchy.children[childKey]}
                                      key={childKey}
                                      contacts={contacts}
                                      providers={providers}
                                      positions={positions.children[childKey]}
                                      level={level + 1}
                                      hierarchiesToDisplay={picked}
                                      documentViewType={documentViewType}
                                      documentID={documentID}
                                      documentState={documentState}
                                      selectPosition={selectPosition}
                                      unselectPosition={unselectPosition}
                                      selectedPositions={subsetSelectedPositions}
                                      editMode={editMode}
                                  />
                              );
                          })}
            </>
        );
    }
}

export default function DocumentPage(props) {
    const { lvitem } = useParams();
    const navigate = useNavigate();
    const [contacts, setContacts] = useState({});
    const [providers, setProviders] = useState([]);
    const [selectedContacts, setSelectedContacts] = useState([]);
    const [hierarchiesToDisplay, setHierarchiesToDisplay] = useState({});
    const [type, setType] = useState('');
    const [selectedPositions, setSelectedPositions] = useState([]);
    const [positionToCopy, setPositionToCopy] = useState();
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [bulkActionState, setBulkActionState] = useState(props.type === 'documents' ? 'assignment' : 'initial');
    const [inBulkTransaction, setInBulkTransaction] = useState(false);
    const [vkPercentage, setVkpercentage] = useState(0);
    const [dd_options, setDdOptions] = useState([]);
    const [editMode, setEditMode] = useState(false);

    const queryClient = useQueryClient();

    const handleTypeChange = function (event) {
        setType(event.target.value);
    };

    const selectPosition = (posID) => {
        setSelectedPositions((parentState) => [...parentState, posID]);
    };

    const unselectPosition = (posID) => {
        setSelectedPositions((parentState) => parentState.filter((item) => item !== posID));
    };

    const handleContactChange = function (newValue) {
        setSelectedContacts(typeof newValue === 'string' ? newValue.split(',') : newValue);
    };

    const theme = useTheme();

    if (!lvitem) {
        if (props.type == 'offers') {
            navigate('/angebote');
        } else {
            navigate('/eingang');
        }
    }

    const recursivelyAddPositionIDs = (hierarchy) => {
        let positionIDs = [];
        if (hierarchy.hasOwnProperty('children')) {
            Object.keys(hierarchy.children).forEach((childKey) => {
                recursivelyAddPositionIDs(hierarchy.children[childKey]);
                positionIDs = positionIDs.concat(hierarchy.children[childKey].containedPositionIDs);
            });
        }
        if (hierarchy.hasOwnProperty('positions')) {
            positionIDs = positionIDs.concat(hierarchy.positions.map((pos) => pos.id));
        }
        hierarchy.containedPositionIDs = positionIDs;
        return positionIDs;
    };

    const groupDocumentData = async () => {
        const response = await DocumentService.get(lvitem);

        let parsedHierarchy = response.data.data;

        let parsedHierarchyData = {
            heading: 'Alle Positionen',
            remarks:
                parsedHierarchy.hasOwnProperty('') && parsedHierarchy[''].hasOwnProperty('remarks')
                    ? parsedHierarchy[''].remarks
                    : [],
            children: {},
            id: ''
        };

        Object.keys(parsedHierarchy).forEach((oz) => {
            // we have tackled the overall remarks above already
            if (oz === '') {
                return;
            }

            const oz_parts = oz.split('.');
            var currentLevel = parsedHierarchyData;
            for (var hierarchyIdx = 0; hierarchyIdx < oz_parts.length; hierarchyIdx++) {
                if (!currentLevel.children.hasOwnProperty(oz_parts[hierarchyIdx])) {
                    currentLevel.children[oz_parts[hierarchyIdx]] = {
                        heading: '',
                        remarks: Array(),
                        children: {},
                        id: oz_parts.slice(0, hierarchyIdx + 1).join('.')
                    };
                }
                currentLevel = currentLevel.children[oz_parts[hierarchyIdx]];
                if (hierarchyIdx === oz_parts.length - 1) {
                    currentLevel.remarks = parsedHierarchy[oz].hasOwnProperty('remarks')
                        ? parsedHierarchy[oz].remarks
                        : [];
                    currentLevel.heading = parsedHierarchy[oz].hasOwnProperty('heading')
                        ? parsedHierarchy[oz].heading
                        : oz;
                    currentLevel.id = oz;
                }
            }
        });

        return {
            data: response.data,
            files: response.data.save_paths,
            hierarchy: parsedHierarchyData,
            hierarchyIDs: Object.keys(parsedHierarchy).filter((element) => element !== ''),
            hierarchyTitles: parsedHierarchy,
            meta: response.data.meta
        };
    };

    const { isLoading, isError, error, data } = useQuery(['documentData', lvitem], () => groupDocumentData(), {
        keepPreviousData: true,
        refetchOnWindowFocus: false,
        staleTime: 1000
    });

    let restrictTypes = '-1';
    if (props.type == 'offers') {
        restrictTypes = '1,2,3';
    }

    const groupPositionData = async () => {
        const response = await PositionService.getMultiple(lvitem, restrictTypes);

        let groupedPositions = { positions: Array(), children: {} };

        response.data.forEach((position) => {
            let positionAssigned = false;
            const split_oz = position.pos_nr.split('.').filter((value) => value !== '');
            var currentLevel = groupedPositions;
            for (var hierarchyIdx = 0; hierarchyIdx < split_oz.length; hierarchyIdx++) {
                if (hierarchyIdx == split_oz.length - 1) {
                    currentLevel.positions.push(position);
                    positionAssigned = true;
                } else {
                    if (!currentLevel.children.hasOwnProperty(split_oz[hierarchyIdx])) {
                        currentLevel.children[split_oz[hierarchyIdx]] = { positions: Array(), children: {} };
                    }
                    currentLevel = currentLevel.children[split_oz[hierarchyIdx]];
                }
            }
            // make sure that we have not lost any position
            if (positionAssigned === false) {
                console.log('Weirdly, I was not able to assign the following position', position);
                groupedPositions.positions.push(position);
            }
        });
        recursivelyAddPositionIDs(groupedPositions);

        return groupedPositions;
    };

    const {
        isLoading: isLoadingPositions,
        isError: isErrorPositions,
        error: errorPositions,
        data: dataPositions
    } = useQuery(['positions', lvitem], () => groupPositionData(), {
        keepPreviousData: true,
        refetchOnWindowFocus: false,
        staleTime: 10000
    });

    const {
        // isLoading: isLoadingProviders,
        // isError: isErrorProviders,
        error: errorProviders,
        data: dataProviders
    } = useQuery(['providerData'], async () => await ProviderService.getMultiple(-1), {
        keepPreviousData: true,
        refetchOnWindowFocus: false,
        staleTime: 10000
    });

    useEffect(() => {
        if (props.type === 'offers' && dataPositions) {
            const new_dd_options = extractPositionIDTitle(dataPositions);
            setDdOptions(new_dd_options);
        }
    }, [dataPositions]);

    const updateDocumentMetaMutation = useMutation((variables) => {
        return DocumentService.patch(variables.lvitem, { levelsToDisplay: variables.hierarchiesToDisplay });
    });

    const { mutateAsync: changePositionMutation } = useMutation(
        (positionID) => {
            return PositionService.patch(positionID, type, selectedContacts);
        },
        {
            onSuccess: (posChanged) => {
                return posChanged;
            },
            onError: (error) => {
                if (error.response) {
                    if (error.response.status === 400) {
                        setErrorMessage(error.response.status + ': Validation error: could not process');
                    } else {
                        setErrorMessage(error.response.status + ': ' + error.response.data.message);
                    }
                } else if (error.request) {
                    setErrorMessage('Server not reachable');
                    console.log(error.request);
                } else {
                    setErrorMessage('Undefined error, please contact your admin');
                    console.log('Error', error.message);
                }
                setTimeout(function () {
                    setErrorMessage('');
                }, 10000);
                return errorMessage;
            }
        }
    );

    const { mutateAsync: copyProductMutation } = useMutation(
        (positionID) => {
            return ProductGroupService.copyProductsForPosition({
                targetPositionID: positionID,
                parentPositionID: positionToCopy
            });
        },
        {
            onError: (error) => {
                if (error.response) {
                    if (error.response.status === 400) {
                        setErrorMessage(error.response.status + ': Validation error: could not process');
                    } else {
                        setErrorMessage(error.response.status + ': ' + error.response.data.message);
                    }
                } else if (error.request) {
                    setErrorMessage('Server not reachable');
                    console.log(error.request);
                } else {
                    setErrorMessage('Undefined error, please contact your admin');
                    console.log('Error', error.message);
                }
                setTimeout(function () {
                    setErrorMessage('');
                }, 10000);
                return errorMessage;
            },
            onSettled: () => {
                setInBulkTransaction(false);
            }
        }
    );

    const changeDBMutation = useMutation(
        () => {
            return ProductService.changeDB(selectedPositions, vkPercentage);
        },
        {
            onSuccess: () => {
                selectedPositions.forEach((positionID) => {
                    queryClient.invalidateQueries(['product_groups', positionID]);
                });
                setSelectedPositions([]);
                setBulkActionState('initial');
            },
            onError: (error) => {
                if (error.response) {
                    if (error.response.status === 400) {
                        setErrorMessage(error.response.status + ': Validation error: could not process');
                    } else {
                        setErrorMessage(error.response.status + ': ' + error.response.data.message);
                    }
                } else if (error.request) {
                    setErrorMessage('Server not reachable');
                    console.log(error.request);
                } else {
                    setErrorMessage('Undefined error, please contact your admin');
                    console.log('Error', error.message);
                }
                setTimeout(function () {
                    setErrorMessage('');
                }, 10000);
                return errorMessage;
            },
            onSettled: () => {
                setInBulkTransaction(false);
            }
        }
    );

    const handleBulkActionDocumentsButton = async () => {
        let mutations = [];
        setInBulkTransaction(true);
        selectedPositions.forEach((positionId) => {
            const promise = changePositionMutation(positionId);
            mutations.push(promise);
        });
        await Promise.all(mutations).then((responses) => {
            queryClient.setQueryData(['positions', lvitem], (oldData) => {
                let newPositions = responses.map((resp) => resp.data);
                return replacePositions(oldData, newPositions);
            });
            setSelectedPositions([]);
            setInBulkTransaction(false);
        });
    };

    const handleBulkActionOffersButton = async () => {
        let mutations = [];
        setInBulkTransaction(true);
        selectedPositions.forEach((positionID) => {
            const promise = copyProductMutation(positionID);
            mutations.push(promise);
        });
        await Promise.all(mutations);
        selectedPositions.forEach((positionID) => {
            queryClient.invalidateQueries(['product_groups', positionID]);
        });
        let positionQueries = [];
        selectedPositions.forEach((positionId) => {
            const promise = PositionService.get(positionId);
            positionQueries.push(promise);
        });
        const responses = await Promise.all(positionQueries);
        queryClient.setQueryData(['positions', lvitem], (oldData) => {
            let newPositions = responses.map((resp) => resp.data);
            return replacePositions(oldData, newPositions);
        });
        setSelectedPositions([]);
        setBulkActionState('initial');
        setInBulkTransaction(false);
    };

    useEffect(() => {
        if (dataProviders) {
            let newContacts = {};
            dataProviders.data.forEach((provider) => {
                provider.contacts.forEach((contact) => {
                    newContacts[contact.id] = {
                        providerID: provider.id,
                        providerName: provider.name,
                        ...contact
                    };
                });
            });
            setContacts(newContacts);
            setProviders(dataProviders.data);
        }
    }, [dataProviders]);

    useEffect(() => {
        if (data) {
            if (data.meta.hasOwnProperty('levels_to_display') && data.data.state <= 2) {
                // if we are in the state of having sent out requests, we display all hierarchies
                setHierarchiesToDisplay(data.meta.levels_to_display);
            } else {
                let hierarchiesIncluded = {};

                data.hierarchyIDs.forEach((oz) => {
                    hierarchiesIncluded[oz] = true;
                });

                setHierarchiesToDisplay(hierarchiesIncluded);
            }
        }
    }, [data]);

    const updateHierarchiesToDisplay = (hierarchiesToDisplay) => {
        setHierarchiesToDisplay(hierarchiesToDisplay);
        updateDocumentMetaMutation.mutate({ lvitem, hierarchiesToDisplay });
    };

    useEffect(() => {
        if (selectedPositions.length > 0) {
            setOpenSnackbar(true);
        } else {
            setOpenSnackbar(false);
        }
    }, [selectedPositions]);

    const handleSelectProductCopy = (e) => {
        e.preventDefault();
        setBulkActionState('copy_products');
    };

    const handleSelectDBchange = (e) => {
        e.preventDefault();
        setBulkActionState('change_db');
    };

    const handleBackButton = (e) => {
        e.preventDefault();
        setBulkActionState('initial');
    };

    const handleVKpercentage = (e) => {
        e.preventDefault();
        setInBulkTransaction(true);
        changeDBMutation.mutate();
    };

    if (isLoading) {
        return (
            <PageLayout>
                <Box>Loading...</Box>
            </PageLayout>
        );
    }

    if (isError) {
        return (
            <PageLayout>
                <Box>
                    <Alert severity="error">
                        {error}
                        {errorProviders}
                    </Alert>
                </Box>
            </PageLayout>
        );
    }

    let title = '';
    if (props.type === 'offers') {
        title = 'Angebot bearbeiten';
    } else if (props.type === 'documents') {
        if (data.data.state === 1) {
            title = 'LV bearbeiten';
        } else {
            title = 'LV abgeschlossen';
        }
    }

    const BULK_ACTIONS = {
        documents: {
            assignment: {
                action: (
                    <Button
                        onClick={handleBulkActionDocumentsButton}
                        disabled={inBulkTransaction}
                        variant="contained"
                        size="small">
                        {inBulkTransaction ? <CircularProgress size={20} sx={{ mr: 2 }} /> : null} Speichern
                    </Button>
                ),
                message: (
                    <div style={{ minWidth: '350px' }}>
                        <Typography sx={{ fontWeight: 'bold', color: 'black', pt: 1, pl: 1, pb: 3 }}>
                            {'Aktion für ' +
                                selectedPositions.length +
                                '  Position' +
                                (selectedPositions.length > 1 ? 'en' : '')}
                        </Typography>
                        <SupplierInput
                            type={type}
                            handleTypeChange={handleTypeChange}
                            allContacts={contacts}
                            allProviders={providers}
                            selectedContacts={selectedContacts}
                            handleContactChange={handleContactChange}
                            float={true}
                        />
                    </div>
                )
            }
        },
        offers: {
            initial: {
                action: <></>,
                message: (
                    <Box sx={{ minWidth: 300, color: 'black' }}>
                        <MenuList>
                            <MenuItem onClick={(e) => handleSelectProductCopy(e)}>
                                <ListItemIcon>
                                    <AddIcon fontSize="small" />
                                </ListItemIcon>
                                <Typography variant="inherit">Produkte aus anderer Position kopieren</Typography>
                            </MenuItem>
                            <MenuItem onClick={(e) => handleSelectDBchange(e)}>
                                <ListItemIcon>
                                    <CreateIcon fontSize="small" />
                                </ListItemIcon>
                                <Typography variant="inherit" noWrap>
                                    DB-Wert ändern
                                </Typography>
                            </MenuItem>
                        </MenuList>
                    </Box>
                )
            },
            copy_products: {
                action: (
                    <>
                        <Button
                            disabled={inBulkTransaction}
                            onClick={(e) => handleBackButton(e)}
                            variant="contained"
                            size="small"
                            sx={{ mr: 1 }}>
                            Zurück
                        </Button>
                        <Button
                            disabled={inBulkTransaction}
                            onClick={handleBulkActionOffersButton}
                            variant="contained"
                            size="small">
                            {inBulkTransaction ? <CircularProgress size={20} sx={{ mr: 2 }} /> : null} Kopieren
                        </Button>
                    </>
                ),
                message: (
                    <div style={{ minWidth: '350px' }}>
                        <Typography sx={{ fontWeight: 'bold', color: 'black', pt: 1, pl: 1, pb: 3 }}>
                            {'Aktion für ' +
                                selectedPositions.length +
                                '  Position' +
                                (selectedPositions.length > 1 ? 'en' : '')}
                        </Typography>

                        <div>
                            <FormControl sx={{ m: 1, minWidth: 200, maxWidth: 300 }}>
                                <InputLabel shrink htmlFor="select-multiple-native">
                                    Kopiere sämtliche Produkte von dieser Position
                                </InputLabel>
                                <Select
                                    defaultValue={''}
                                    onChange={(e) => setPositionToCopy(e.target.value)}
                                    label="Kopiere sämtliche Produkte von"
                                    inputProps={{
                                        id: 'select-multiple-native'
                                    }}
                                    MenuProps={{
                                        anchorOrigin: {
                                            vertical: 'bottom',
                                            horizontal: 'left'
                                        },
                                        transformOrigin: {
                                            vertical: 'top',
                                            horizontal: 'left'
                                        }
                                    }}>
                                    {dd_options
                                        .filter((dd_option) => !selectedPositions.includes(dd_option.id))
                                        .map((dd_option) => (
                                            <MenuItem key={dd_option.id} value={dd_option.id}>
                                                {dd_option.title}
                                            </MenuItem>
                                        ))}
                                </Select>
                            </FormControl>
                        </div>
                    </div>
                )
            },
            change_db: {
                action: (
                    <>
                        <Button
                            disabled={inBulkTransaction}
                            onClick={(e) => handleBackButton(e)}
                            variant="contained"
                            size="small"
                            sx={{ mr: 1 }}>
                            Zurück
                        </Button>
                        <Button
                            onClick={(e) => handleVKpercentage(e)}
                            variant="contained"
                            size="small"
                            disabled={inBulkTransaction}>
                            {inBulkTransaction ? <CircularProgress size={20} sx={{ mr: 2 }} /> : null} Speichern
                        </Button>
                    </>
                ),
                message: (
                    <div style={{ minWidth: '350px' }}>
                        <Typography sx={{ fontWeight: 'bold', color: 'black', pt: 1, pl: 1, pb: 3 }}>
                            {'Aktion für ' +
                                selectedPositions.length +
                                '  Position' +
                                (selectedPositions.length > 1 ? 'en' : '')}
                        </Typography>
                        <TextField
                            id="product-DB%"
                            label="DB%"
                            type="number"
                            fullWidth
                            sx={{ mb: 1 }}
                            defaultValue={vkPercentage}
                            helperText={
                                'DB% für alle Produkte der ausgewählten Positionen um den eingegebenen DB%-Wert ' +
                                'verändern. Beispiel: "1,8" = +1,8 Prozentpunkte (alter Wert: 2 => neuer Wert: 3,8), ' +
                                '-0,4 = -0,4 Prozentpunkte (alter Wert: 3,2, neuer Wert: 2,8)'
                            }
                            size="small"
                            onChange={(e) => setVkpercentage(e.target.value)}
                            InputProps={{
                                locale: 'de-DE',
                                step: 0.1
                            }}
                        />
                    </div>
                )
            }
        }
    };

    return (
        <PageLayout title={title}>
            <Snackbar sx={{ zIndex: 99 }} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open={openSnackbar}>
                <SnackbarContent
                    sx={{
                        backgroundColor: theme.palette.neutral[100],
                        border: 1,
                        borderColor: theme.palette.fassbender.green
                    }}
                    action={BULK_ACTIONS[props.type][bulkActionState].action}
                    message={BULK_ACTIONS[props.type][bulkActionState].message}
                />
            </Snackbar>
            <Box
                component="main"
                sx={{
                    flexGrow: 1,
                    py: 2
                }}>
                <DocumentMeta
                    meta={data.meta}
                    created_at={data.data.created_at}
                    positions={dataPositions}
                    state={data.data.state}
                    hierarchy={data.hierarchy}
                    hierarchyTitles={data.hierarchyTitles}
                    setHierarchiesToDisplay={updateHierarchiesToDisplay}
                    offerValidUntil={data.data.offer_valid_until}
                    priceValidUntil={data.data.price_valid_until}
                    hierarchiesToDisplay={hierarchiesToDisplay}
                    id={lvitem}
                    user={data.data.user.public_name ? data.data.user.public_name : data.data.user.name}
                    documentViewType={props.type}
                    offerFiles={data.files}
                    minPrice={data.data.min_price}
                    maxPrice={data.data.max_price}
                    totalPrice={data.data.total_price}
                    client={data.data?.client}
                    requests={data.data?.requests}
                    documentLocked={data.data?.locked}
                    editMode={editMode}
                    setEditMode={setEditMode}
                    allContacts={contacts}
                />
                <Box sx={{ m: 3 }}>
                    {isLoadingPositions ? (
                        <Box>
                            <Grid container sx={{ mt: 2, mb: 2 }}>
                                <Grid alignItems="center" sx={{ display: 'flex' }}>
                                    <CircularLoading width="40px" mr="4" /> <>Positionen laden...</>
                                </Grid>
                            </Grid>
                        </Box>
                    ) : isErrorPositions ? (
                        <Box>
                            <Alert severity="error">{errorPositions.message}</Alert>
                        </Box>
                    ) : (
                        <RecursiveHierarchicalPositionDisplay
                            hierarchy={data.hierarchy}
                            positions={dataPositions}
                            level={0}
                            contacts={contacts}
                            providers={providers}
                            documentViewType={props.type}
                            documentID={lvitem}
                            hierarchiesToDisplay={hierarchiesToDisplay}
                            documentState={data.data.state}
                            selectPosition={selectPosition}
                            unselectPosition={unselectPosition}
                            selectedPositions={selectedPositions}
                            editMode={editMode}
                        />
                    )}
                </Box>
            </Box>
        </PageLayout>
    );
}
