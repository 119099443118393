import API from '../axios.js';
import AuthUtils from '../auth/utils.js';

class UserService {
    delete(userID) {
        return API({
            method: 'delete',
            url: '/user/' + encodeURIComponent(userID),
            headers: AuthUtils.authHeaders()
        });
    }

    put(data) {
        let headers = AuthUtils.authHeaders();
        return API({
            method: 'put',
            url: '/users',
            data: data,
            headers: headers
        });
    }

    patch(user) {
        return API({
            method: 'patch',
            url: '/user/' + encodeURIComponent(user.public_id),
            headers: AuthUtils.authHeaders(),
            data: {
                user
            }
        });
    }

    get(userID) {
        let headers = AuthUtils.authHeaders();
        return API({
            method: 'get',
            url: '/user/' + encodeURIComponent(userID),
            headers: headers
        });
    }

    getMultiple(page = 1) {
        return API({
            method: 'get',
            url: '/users?page=' + encodeURIComponent(page),
            headers: AuthUtils.authHeaders()
        });
    }
}

export default new UserService();
