import AuthUtils from '../auth/utils.js';
import API from '../axios.js';

class RequestService {
    getAll() {
        return API({
            method: 'get',
            url: '/requests',
            headers: AuthUtils.authHeaders()
        });
    }

    patch(requestId, contactId) {
        let headers = AuthUtils.authHeaders();
        return API({
            method: 'patch',
            url: '/request/' + encodeURIComponent(requestId),
            data: {
                contact_id: contactId
            },
            headers: headers
        });
    }
}

export default new RequestService();
