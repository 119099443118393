import AuthUtils from '../auth/utils.js';
import API from '../axios.js';

class ClientService {
    delete(clientID) {
        return API({
            method: 'delete',
            url: '/client/' + encodeURIComponent(clientID),
            headers: AuthUtils.authHeaders()
        });
    }
    patch(client) {
        return API({
            method: 'patch',
            url: '/client/' + encodeURIComponent(client.id),
            headers: AuthUtils.authHeaders(),
            data: {
                client
            }
        });
    }

    put(data) {
        let headers = AuthUtils.authHeaders();
        return API({ method: 'post', url: '/clients', data: data, headers: headers });
    }

    get(clientID) {
        return API({
            method: 'get',
            url: '/client/' + encodeURIComponent(clientID),
            headers: AuthUtils.authHeaders()
        });
    }

    search(filter, page = 1) {
        return API({
            method: 'get',
            url: '/clients/search?page=' + encodeURIComponent(page) + '&filter=' + encodeURIComponent(filter),
            headers: AuthUtils.authHeaders()
        });
    }

    getMultiple(page = 1) {
        return API({
            method: 'get',
            url: '/clients?page=' + encodeURIComponent(page),
            headers: AuthUtils.authHeaders()
        });
    }
}

export default new ClientService();
