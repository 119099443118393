import React, { useState } from 'react';
import ProviderService from '../api/services/Provider';
import { Button } from '@mui/material';
import Alert from '@mui/material/Alert';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useMutation, useQueryClient } from 'react-query';
import TextField from '@mui/material/TextField';
import AddIcon from '@mui/icons-material/Add';
import { CircularLoading } from './CircularLoading';

export default function RegisterDialog() {
    const [providerName, setProviderName] = useState('');
    const [open, setOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [inTransaction, setInTransaction] = useState(false);

    const queryClient = useQueryClient();

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setProviderName('');
        setOpen(false);
        setErrorMessage('');
        setInTransaction(false);
    };

    const providerMutation = useMutation(
        () => {
            return ProviderService.put({ name: providerName });
        },
        {
            onSuccess: () => {
                queryClient.invalidateQueries('providers');
                handleClose();
            },
            onError: (error) => {
                console.log(error.response);
                if (error.response) {
                    if (error.response.status === 400) {
                        setErrorMessage(error.response.status + ': Validation error: could not process');
                    } else {
                        setErrorMessage(error.response.status + ': ' + error.response.data.message);
                    }
                } else if (error.request) {
                    setErrorMessage('Server not reachable');
                    console.log(error.request);
                } else {
                    setErrorMessage('Undefined error, please contact your admin');
                    console.log('Error', error.message);
                }
            },
            onSettled: () => {
                setInTransaction(false);
            }
        }
    );

    return (
        <span>
            <Button variant="outlined" startIcon={<AddIcon />} onClick={handleClickOpen} sx={{ mr: 1 }}>
                Lieferant hinzufügen
            </Button>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Füge einen neuen Lieferanten hinzu</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {errorMessage ? <Alert severity="error">{errorMessage}</Alert> : ''}
                    </DialogContentText>
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="new-provider"
                        label="Firmenname"
                        name="new-provider"
                        autoComplete="off"
                        autoFocus
                        value={providerName}
                        onChange={(e) => setProviderName(e.target.value)}
                    />
                    {inTransaction ? (
                        <>
                            <CircularLoading width="40px" mr="4" /> Lieferant wird hinzugefügt...
                        </>
                    ) : null}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Abbrechen</Button>
                    <Button
                        disabled={inTransaction}
                        onClick={() => {
                            setInTransaction(true);
                            providerMutation.mutate();
                        }}>
                        Lieferant hinzufügen
                    </Button>
                </DialogActions>
            </Dialog>
        </span>
    );
}
