import DeleteIcon from '@mui/icons-material/Delete';
import SearchIcon from '@mui/icons-material/Search';
import {
    Alert,
    Box,
    Button,
    Container,
    Divider,
    Grid,
    InputAdornment,
    TablePagination,
    TextField,
    Typography
} from '@mui/material';
import React, { useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import ProviderService from '../api/services/Provider';
import AddContactDialog from '../components/AddContactDialog';
import AddProviderDialog from '../components/AddProviderDialog';
import { CircularLoading } from '../components/CircularLoading';
import { DeleteConfirmation } from '../components/DeleteConfirmation';
import ImportProvidersDialog from '../components/ImportProvidersDialog';
import { PageLayout } from '../components/PageLayout';
import ProviderContacts from '../components/ProviderContacts';
import { useDebounce } from '../utils/CustomHooks';
// import ManageHistoryIcon from '@mui/icons-material/ManageHistory';
import 'moment/locale/de';

function ProviderRow({ provider }) {
    const [errorMessage, setErrorMessage] = useState('');
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const queryClient = useQueryClient();

    if (errorMessage !== '') {
        return <Alert severity="error">{errorMessage}</Alert>;
    }

    return (
        <>
            {showDeleteConfirmation && (
                <DeleteConfirmation
                    entity={provider}
                    onSuccess={() => {
                        queryClient.invalidateQueries('providers');
                    }}
                    mutationFunction={() => {
                        return ProviderService.delete(provider.id);
                    }}
                    confirmationTitle="Lieferant & Kontakte Löschen?"
                    confirmationMessage={`Lieferant "${provider.name}" wird gelöscht`}
                    loadingMessage={'Lieferant löschen'}
                    showDeleteConfirmation={showDeleteConfirmation}
                    setShowDeleteConfirmation={setShowDeleteConfirmation}
                    errorMessage={errorMessage}
                    setErrorMessage={setErrorMessage}
                />
            )}
            <Grid container key={provider.id}>
                <Grid item xs={12} sx={{ mt: 2, mb: 2 }}>
                    <Divider />
                </Grid>
                <Grid item md={3}>
                    <Typography variant="h5">{provider.name}</Typography>
                    <Typography>{provider.name2}</Typography>
                    <Button
                        variant="outlined"
                        size="small"
                        color="error"
                        startIcon={<DeleteIcon fontSize="small" />}
                        onClick={() => {
                            setShowDeleteConfirmation(true);
                        }}
                        sx={{ mt: 2 }}>
                        Lieferant & Kontakte löschen
                    </Button>
                    <AddContactDialog providerID={provider.id} />
                </Grid>
                <ProviderContacts providerID={provider.id} />
            </Grid>
        </>
    );
}

export default function ProvidersPage() {
    const [page, setPage] = useState(0);
    const [filter, setFilter] = useState('');
    const debouncedFilter = useDebounce(filter, 500);

    const updateFilter = (newFilter) => {
        setFilter(newFilter);
        setPage(0);
    };

    const { isLoading, isError, error, data } = useQuery(
        ['providers', page, debouncedFilter],
        () => {
            if (debouncedFilter === '') {
                return ProviderService.getMultiple(page);
            } else {
                return ProviderService.search(debouncedFilter, page);
            }
        },
        {
            keepPreviousData: true,
            refetchOnWindowFocus: false,
            staleTime: 10000
        }
    );

    return (
        <PageLayout title="Lieferanten bearbeiten">
            <Box
                component="main"
                sx={{
                    flexGrow: 1,
                    py: 8
                }}>
                <Container maxWidth={false}>
                    <Grid container>
                        <Grid item md={4} sx={{ justifyContent: 'left', display: 'flex' }}>
                            <TextField
                                id="outlined-basic"
                                defaultValue={filter}
                                label="Suchen"
                                onChange={(e) => updateFilter(e.target.value)}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <SearchIcon />
                                        </InputAdornment>
                                    )
                                }}
                            />
                            {!isLoading && !isError && data.data.providers.length ? (
                                <TablePagination
                                    component="div"
                                    count={data.data.pagination.num_total}
                                    page={page}
                                    rowsPerPage={data.data.pagination.num_rows_per_page}
                                    rowsPerPageOptions={[]}
                                    onPageChange={(event, newPage) => setPage(newPage)}
                                />
                            ) : (
                                <></>
                            )}
                        </Grid>
                        <Grid item md={8} sx={{ textAlign: 'right' }}>
                            <AddProviderDialog />
                            <ImportProvidersDialog />
                        </Grid>
                    </Grid>
                    <Box sx={{ mt: 3 }}>
                        <div>
                            {isLoading ? (
                                <CircularLoading variant="absolute" />
                            ) : isError ? (
                                <Alert severity="error">{error.message}</Alert>
                            ) : data.data.providers.length ? (
                                <>
                                    {data.data.providers.map(function (provider) {
                                        return <ProviderRow provider={provider} key={provider.id} />;
                                    })}
                                    <TablePagination
                                        component="div"
                                        count={data.data.pagination.num_total}
                                        page={page}
                                        rowsPerPage={data.data.pagination.num_rows_per_page}
                                        rowsPerPageOptions={[]}
                                        onPageChange={(event, newPage) => setPage(newPage)}
                                    />
                                </>
                            ) : (
                                <div>
                                    <p>Keine Lieferanten gelistet</p>
                                </div>
                            )}
                        </div>
                    </Box>
                </Container>
            </Box>
        </PageLayout>
    );
}
