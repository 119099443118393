import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Alert from '@mui/material/Alert';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider, useTheme } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AuthService from '../api/services/Auth.js';

export default function LoginPage() {
    const [username, setUserName] = useState('');
    const [password, setPassword] = useState('');
    // TODO: display messages & handle errors
    const [errorMessage, setErrorMessage] = useState('');
    const navigate = useNavigate();
    const theme = useTheme();

    const handleSubmit = async (e) => {
        e.preventDefault();

        AuthService.login(username, password)
            .then(() => {
                navigate('/eingang', { replace: true });
            })
            .catch((error) => {
                if (error.response) {
                    if (error.response.status === 401) {
                        setErrorMessage('Could not authenticate. Your user name / password is invalid.');
                    } else {
                        setErrorMessage(error.response.status + ': The server can not process this request');
                    }
                } else if (error.request) {
                    setErrorMessage('Server not reachable');
                    console.log(error.request);
                } else {
                    setErrorMessage('Undefined error, please contact your admin');
                    console.log('Error', error.message);
                }
            });
    };

    return (
        <ThemeProvider theme={theme}>
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center'
                    }}>
                    <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                        <LockOutlinedIcon />
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        Sign in
                    </Typography>
                    <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                        {errorMessage ? <Alert severity="error">{errorMessage}</Alert> : ''}
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="user"
                            label="Username"
                            name="user"
                            autoComplete="user"
                            autoFocus
                            value={username}
                            onChange={(e) => setUserName(e.target.value)}
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label="Password"
                            type="password"
                            id="password"
                            autoComplete="current-password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                        <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
                            Sign In
                        </Button>
                    </Box>
                </Box>
            </Container>
        </ThemeProvider>
    );
}
