export function recursivelyFindChildren(hierarchy) {
    let returnArr = [hierarchy.id];
    if (!hierarchy.hasOwnProperty('children') || Object.keys(hierarchy.children).length == 0) {
        return returnArr;
    }
    Object.keys(hierarchy.children).forEach((childKey) => {
        returnArr.push(...recursivelyFindChildren(hierarchy.children[childKey]));
    });
    return returnArr;
}

export function checkUnsettledChildren(positions) {
    if (positions === undefined) {
        return false;
    }
    if (!positions.hasOwnProperty('children') || Object.keys(positions.children).length == 0) {
        for (let pos of positions.positions) {
            if (pos.type === '1' && pos.contacts_to_request === '[]') {
                return true;
            }
        }
        return false;
    } else {
        let boolList = Object.keys(positions.children).map((childKey) =>
            checkUnsettledChildren(positions.children[childKey])
        );

        return boolList.includes(true);
    }
}

// Recursive function to replace positions.
function replacePositionRecursively(oldPositions, positionData) {
    for (var idx = 0; idx < oldPositions.positions.length; idx++) {
        if (oldPositions.positions[idx].id === positionData.id) {
            oldPositions.positions[idx] = positionData;
            return true;
        }
    }
    if (oldPositions.hasOwnProperty('children')) {
        const childKeys = Object.keys(oldPositions.children);
        for (var jdx = 0; jdx < childKeys.length; jdx++) {
            if (replacePositionRecursively(oldPositions.children[childKeys[jdx]], positionData)) {
                return true;
            }
        }
    }
    return false;
}

// Driver function for replacing positions by creating a new object reference
export function replacePositions(oldPositions, positionData) {
    let updatedPositions = JSON.parse(JSON.stringify(oldPositions)); //Deep copy
    if (!Array.isArray(positionData)) {
        positionData = [positionData];
    }
    positionData.forEach((posChanged) => {
        let success = replacePositionRecursively(updatedPositions, posChanged);
        if (!success) {
            console.warn('Could not find position to replace: ' + posChanged);
        }
    });

    return updatedPositions;
}
