import React, { useState } from 'react';
import UserService from '../api/services/User';
import { Button } from '@mui/material';
import Alert from '@mui/material/Alert';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useQueryClient } from 'react-query';
import TextField from '@mui/material/TextField';
import { CircularLoading } from './CircularLoading';

export default function RegisterDialog() {
    const [userName, setUserName] = useState('');
    const [password, setPassword] = useState('');
    const [open, setOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [inTransaction, setInTransaction] = useState(false);

    const queryClient = useQueryClient();

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setUserName('');
        setPassword('');
        setOpen(false);
        setErrorMessage('');
    };

    const handleSubmission = () => {
        setInTransaction(true);
        const formData = new FormData();

        formData.append('name', userName);
        formData.append('password', password);

        // TODO: catch exceptions, display meaningful status
        return UserService.put({ name: userName, password: password })
            .then((response) => {
                let user_id = response.data.user_id;

                // we invalidate all document query results since we do not know how the added document changed order of
                // existing documents
                queryClient.invalidateQueries('users');
                handleClose();

                return user_id;
            })
            .catch((error) => {
                console.log(error.response);
                if (error.response) {
                    if (error.response.status === 400) {
                        setErrorMessage(error.response.status + ': Validation error: could not process');
                    } else {
                        setErrorMessage(error.response.status + ': ' + error.response.data.message);
                    }
                } else if (error.request) {
                    setErrorMessage('Server not reachable');
                    console.log(error.request);
                } else {
                    setErrorMessage('Undefined error, please contact your admin');
                    console.log('Error', error.message);
                }
            })
            .finally(() => {
                setInTransaction(false);
            });
    };

    return (
        <div>
            <Button variant="outlined" onClick={handleClickOpen}>
                Nutzer hinzufügen
            </Button>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Neuen Benutzer hinzufügen</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {errorMessage ? <Alert severity="error">{errorMessage}</Alert> : ''}
                    </DialogContentText>
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="new-user"
                        label="Username"
                        name="new-user"
                        autoComplete="off"
                        autoFocus
                        value={userName}
                        disabled={inTransaction}
                        onChange={(e) => setUserName(e.target.value)}
                    />
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="new-password"
                        label="Password"
                        type="password"
                        id="new-password"
                        autoComplete="new-password"
                        value={password}
                        disabled={inTransaction}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                    {inTransaction ? (
                        <>
                            <CircularLoading width="1.5rem" mr={2} /> Nutzer wird hinzugefügt...
                        </>
                    ) : null}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button onClick={handleSubmission} disabled={inTransaction}>
                        Nutzer hinzufügen
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
