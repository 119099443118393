import SaveIcon from '@mui/icons-material/Save';
import { Alert, Box, Button, Container, Grid, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import AuthUtils from '../api/auth/utils.js';
import UserService from '../api/services/User.js';
import { CircularLoading } from '../components/CircularLoading';
import { PageLayout } from '../components/PageLayout';

export default function UserEditPage() {
    const { userID } = useParams();
    const navigate = useNavigate();

    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [address, setAddress] = useState('');
    const [place, setPlace] = useState('');

    const [inTransaction, setInTransaction] = useState(false);

    const currentUser = AuthUtils.getCurrentUser();
    const title = 'Benutzeraccount bearbeiten';
    const canEdit = currentUser.admin || currentUser.public_id === userID;

    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');

    const queryClient = useQueryClient();

    if (!userID) {
        navigate('/users');
    }

    const { isLoading, isError, error, data } = useQuery(['userData', userID], () => UserService.get(userID), {
        keepPreviousData: true,
        refetchOnWindowFocus: false,
        staleTime: 1000
    });

    useEffect(() => {
        if (data) {
            setName(data.data.public_name !== null ? data.data.public_name : '');
            setPhone(data.data.phone !== null ? data.data.phone : '');
            setEmail(data.data.email !== null ? data.data.email : '');
            setAddress(data.data.address !== null ? data.data.address : '');
            setPlace(data.data.place !== null ? data.data.place : '');
        }
    }, [data]);

    const updateUserMutation = useMutation(
        () => {
            return UserService.patch({
                public_id: userID,
                name: name,
                phone: phone,
                email: email,
                address: address,
                place: place
            });
        },
        {
            onSuccess: (userUpdated) => {
                queryClient.setQueryData(['userData', userID], () => {
                    return userUpdated;
                });
                setSuccessMessage('Nutzerdaten gespeichert');
                setTimeout(() => {
                    setSuccessMessage('');
                }, 10000);
            },
            onError: (error) => {
                if (error.response) {
                    if (error.response.status === 400) {
                        setErrorMessage(error.response.status + ': Validation error: could not process');
                    } else {
                        setErrorMessage(error.response.status + ': ' + error.response.data.message);
                    }
                } else if (error.request) {
                    setErrorMessage('Server not reachable');
                    console.log(error.request);
                } else {
                    setErrorMessage('Undefined error, please contact your admin');
                    console.log('Error', error.message);
                }
                setTimeout(function () {
                    setErrorMessage('');
                }, 10000);
                return errorMessage;
            },
            onSettled: () => {
                setInTransaction(false);
            }
        }
    );

    if (isLoading) {
        return (
            <PageLayout title={title}>
                <Box>Loading...</Box>
            </PageLayout>
        );
    }

    if (isError) {
        return (
            <PageLayout title={title}>
                <Box>
                    <Alert severity="error">{error}</Alert>
                </Box>
            </PageLayout>
        );
    }

    return (
        <PageLayout title={title}>
            <Container maxWidth={false} sx={{ py: 4 }}>
                {errorMessage ? (
                    <Alert severity="error" sx={{ mb: 2 }}>
                        {errorMessage}
                    </Alert>
                ) : (
                    ''
                )}
                {successMessage ? (
                    <Alert severity="success" sx={{ mb: 2 }}>
                        {successMessage}
                    </Alert>
                ) : (
                    ''
                )}
                <Box component="form" autoComplete="off" sx={{ display: 'flex', flexDirection: 'column' }}>
                    <TextField
                        id="user-name"
                        label="Anzeigename"
                        helperText="Der Anzeigename wird als Name in Anfragen / Angeboten angezeigt"
                        value={name}
                        size="small"
                        sx={{ mb: 2, maxWidth: 350 }}
                        fullWidth
                        onChange={(e) => setName(e.target.value)}
                        disabled={!canEdit || inTransaction}
                    />
                    <TextField
                        id="user-email"
                        fullWidth
                        sx={{ mb: 2, maxWidth: 350 }}
                        size="small"
                        type={'email'}
                        label="E-Mail"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        disabled={!canEdit || inTransaction}
                    />
                    <TextField
                        id="user-phone"
                        fullWidth
                        size="small"
                        sx={{ mb: 2, maxWidth: 350 }}
                        label="Telefonnummer"
                        helperText="Die Telefonnummer wird als Kontakt in Anfragen / Angeboten angezeigt"
                        value={phone}
                        onChange={(e) => setPhone(e.target.value)}
                        disabled={!canEdit || inTransaction}
                    />
                    <TextField
                        id="user-address"
                        fullWidth
                        size="small"
                        sx={{ mb: 2, maxWidth: 350 }}
                        label="Adresse"
                        helperText="Die Adresse wird als Absendeadresse in Anfragen / Angeboten angezeigt"
                        value={address}
                        multiline
                        minRows={3}
                        onChange={(e) => setAddress(e.target.value)}
                        disabled={!canEdit || inTransaction}
                    />
                    <TextField
                        id="user-place"
                        fullWidth
                        size="small"
                        sx={{ mb: 2, maxWidth: 350 }}
                        label="Ort"
                        helperText="Der Ort wird u.A. im Kopfteil von Anfragen / Angeboten verwendet"
                        value={place}
                        onChange={(e) => setPlace(e.target.value)}
                        disabled={!canEdit || inTransaction}
                    />
                    {inTransaction ? (
                        <Grid container sx={{ mt: 2, mb: 2 }}>
                            <Grid alignItems="center" sx={{ display: 'flex' }}>
                                <CircularLoading width="1.2rem" mr={2} /> Änderungen speichern...
                            </Grid>
                        </Grid>
                    ) : null}
                    <Button
                        startIcon={<SaveIcon />}
                        onClick={() => {
                            if (canEdit) {
                                setInTransaction(true);
                                updateUserMutation.mutate();
                            }
                        }}
                        sx={{ width: 150 }}
                        disabled={!canEdit || inTransaction}
                        variant="contained">
                        Speichern
                    </Button>
                </Box>
            </Container>
        </PageLayout>
    );
}
