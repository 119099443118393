const parseJwt = function (token) {
    try {
        return JSON.parse(window.atob(token.split('.')[1]));
    } catch (e) {
        return null;
    }
};

class AuthUtils {
    getData() {
        const storedData = localStorage.getItem('user');
        let data = storedData ? JSON.parse(storedData) : null;
        return data;
    }

    setData(data) {
        localStorage.setItem('user', JSON.stringify(data));
        document.cookie = 'access_token_cookie=' + data?.access_token + ';path=/';
    }

    setAccessToken(accessToken) {
        let currentData = this.getData();
        currentData.access_token = accessToken;
        document.cookie = 'access_token_cookie=' + accessToken + ';path=/';
        this.setData(currentData);
    }

    getAccessToken() {
        const data = this.getData();
        return data?.access_token;
    }

    getRefreshToken() {
        const data = this.getData();
        return data?.refresh_token;
    }

    getCurrentUser() {
        const data = this.getData();
        return data?.user;
    }

    deleteData() {
        localStorage.removeItem('user');
    }

    authHeaders(refresh = false) {
        let auth_token = null;

        if (refresh) {
            auth_token = this.getRefreshToken();
        } else {
            auth_token = this.getAccessToken();
        }

        if (auth_token) {
            return { Authorization: 'Bearer ' + auth_token };
        } else {
            return {};
        }
    }

    tokenIsValid() {
        const data = this.getData();
        if (data) {
            const decodedJwt = parseJwt(data.access_token);

            return decodedJwt.exp * 1000 >= Date.now();
        }
        return false;
    }

    hasRefreshToken() {
        const data = this.getData();
        return !!(data && data.refresh_token);
    }
}

export default new AuthUtils();
