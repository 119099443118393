import AuthUtils from '../auth/utils.js';
import API from '../axios.js';

class DocumentService {
    delete(documentID) {
        return API({
            method: 'delete',
            url: '/document/' + encodeURIComponent(documentID),
            headers: AuthUtils.authHeaders()
        });
    }

    get(documentID) {
        return API({
            method: 'get',
            url: '/document/' + encodeURIComponent(documentID),
            headers: AuthUtils.authHeaders()
        });
    }

    patch(documentID, data) {
        return API({
            method: 'patch',
            url: '/document/' + encodeURIComponent(documentID),
            headers: AuthUtils.authHeaders(),
            data: data
        });
    }

    copyDocumentForClient(documentID, client) {
        return API({
            method: 'post',
            url: '/document/' + encodeURIComponent(documentID),
            headers: AuthUtils.authHeaders(),
            data: client
        });
    }

    put(formData) {
        let headers = AuthUtils.authHeaders();
        return API({ method: 'post', url: '/documents', data: formData, headers: headers });
    }

    getMultiple(page = 1, size = 25, columnFilters, globalFilter, sorting, typesToInclude = '1,2,3') {
        return API({
            method: 'get',
            url:
                '/documents?page=' +
                encodeURIComponent(page) +
                '&size=' +
                encodeURIComponent(size) +
                '&columnFilters=' +
                encodeURIComponent(JSON.stringify(columnFilters)) +
                '&globalFilter=' +
                encodeURIComponent(globalFilter) +
                '&sorting=' +
                encodeURIComponent(JSON.stringify(sorting)) +
                '&includeTypes=' +
                encodeURIComponent(typesToInclude),
            headers: AuthUtils.authHeaders()
        });
    }
}

export default new DocumentService();
