import { useTheme } from '@emotion/react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AddIcon from '@mui/icons-material/PersonAdd';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Autocomplete,
    Avatar,
    Box,
    Button,
    Checkbox,
    Chip,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography
} from '@mui/material';
import DOMPurify from 'isomorphic-dompurify';
import React, { useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import PositionService from '../api/services/Position.js';
import CombinedDialog from '../components/AddProviderContact';
import { replacePositions } from '../utils/HierarchyUtils.js';
import Products from './Products.js';

const POSITION_TYPE = {
    1: 'Individuelle Anfrage',
    2: 'Standard Preisliste',
    3: 'FT-Lagerartikel',
    4: 'Irrelevant'
};

export function SupplierInput({
    positionID = '-1',
    type,
    handleTypeChange,
    allContacts,
    allProviders,
    selectedContacts,
    handleContactChange,
    handleContactAdd,
    hasProduct,
    editMode,
    float = false
}) {
    const [dialogOpen, setDialogOpen] = useState(false);
    if (editMode) {
        if (hasProduct) {
            return 'Positionen, denen bereits Produkte zugewiesen wurden, können nicht bearbeitet werden.';
        } else if (POSITION_TYPE[type] !== 'Individuelle Anfrage') {
            return 'Nur Positionen des Typs "Individuelle Anfrage" können bearbeitet werden';
        }
    }
    return (
        <div style={{ zIndex: 100, display: 'block' }}>
            <FormControl variant="standard" sx={{ width: 180, m: 1, mt: -1 }}>
                <InputLabel id={'simple-select-opt-wahl-' + positionID}>Zuordnung wählen</InputLabel>
                <Select
                    labelId={'simple-select-opt-wahl' + positionID}
                    id={'simple-select-' + positionID}
                    label="Q1"
                    size="small"
                    value={type}
                    onChange={handleTypeChange}>
                    {Object.keys(POSITION_TYPE).map((key) => (
                        <MenuItem id={'menu-item-' + positionID + '-' + key} key={key} value={key}>
                            {POSITION_TYPE[key]}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
            {type === '1' && !editMode && (
                <>
                    <Button
                        startIcon={<AddIcon />}
                        onClick={() => {
                            setDialogOpen(true);
                        }}>
                        Neuen Kontakt erstellen
                    </Button>
                    <CombinedDialog
                        open={dialogOpen}
                        setOpen={setDialogOpen}
                        addContact={handleContactAdd}
                        allProviders={allProviders}
                    />
                </>
            )}
            {type === '1' && Object.keys(allContacts).length > 0 ? (
                <>
                    <Autocomplete
                        multiple
                        id={positionID + '-ct'}
                        value={selectedContacts}
                        disabled={editMode}
                        options={Object.values(allContacts)
                            .sort((a, b) => -b.providerName.localeCompare(a.providerName))
                            .map((contact) => contact.id)}
                        groupBy={(contactID) => allContacts[contactID].providerName}
                        disableCloseOnSelect
                        getOptionLabel={(contactID) =>
                            allContacts[contactID].providerName + ': ' + allContacts[contactID].email
                        }
                        onChange={(_event, newValue) => {
                            handleContactChange(newValue);
                        }}
                        renderOption={(localProps, contactID) => {
                            localProps.key = contactID;
                            return <li {...localProps}>{allContacts[contactID].email}</li>;
                        }}
                        sx={{ m: 1 }}
                        size={float ? 'large' : 'small'}
                        renderInput={(params) => {
                            let providers_missing = selectedContacts.length === 0;
                            return (
                                <TextField
                                    {...params}
                                    error={providers_missing}
                                    helperText={providers_missing ? 'Keine Lieferanten gewählt' : ''}
                                    placeholder={'Suche Lieferanten'}
                                    label="Lieferanten wählen"
                                    variant="standard"
                                />
                            );
                        }}
                    />
                </>
            ) : (
                <></>
            )}
        </div>
    );
}

function SupplierChip({ type, positionTypeColor, allContacts, contacts }) {
    return (
        <>
            <Chip label={POSITION_TYPE[type]} variant="contained" color={positionTypeColor} />
            {type === '1' && Object.keys(allContacts).length > 0 ? (
                <>
                    {contacts.map(function (contactID) {
                        return (
                            <Chip
                                key={contactID}
                                label={allContacts[contactID].providerName}
                                variant="outlined"
                                avatar={<Avatar>{allContacts[contactID].providerName[0]}</Avatar>}
                            />
                        );
                    })}
                </>
            ) : (
                <></>
            )}
        </>
    );
}

export default function Position(props) {
    const [type, setType] = useState(props.type);
    const [expand, setExpand] = useState(false);
    const [contacts, setContacts] = useState(
        props.contacts_to_request === null ? [] : JSON.parse(props.contacts_to_request)
    );
    const [checked, setChecked] = useState(false);
    const theme = useTheme();
    const queryClient = useQueryClient();

    const mutation = useMutation(
        (variables) => {
            if (variables.field === 'type') {
                return PositionService.patch(props.positionID, variables.value, contacts);
            } else if (variables.field === 'contacts') {
                return PositionService.patch(props.positionID, type, variables.value);
            }
        },
        {
            onSuccess: (response) => {
                queryClient.setQueryData(['positions', props.documentID], (oldData) =>
                    replacePositions(oldData, response.data)
                );
            }
        }
    );

    const handleTypeChange = function (event) {
        setType(event.target.value);
        mutation.mutate({ field: 'type', value: event.target.value });
    };

    const handleChecked = (e) => {
        setChecked(e.target.checked);
    };

    const handleContactAdd = function (newContact) {
        const newContacts = [...contacts, newContact];
        mutation.mutate({ field: 'contacts', value: newContacts });
    };

    const handleContactChange = function (newValue) {
        const newContacts = typeof newValue === 'string' ? newValue.split(',') : newValue;
        setContacts(newContacts);
        mutation.mutate({ field: 'contacts', value: newContacts });
    };

    const toggleAccordion = () => {
        setExpand((prev) => !prev);
    };

    React.useEffect(() => {
        setType(props.type);
    }, [props.type]);

    React.useEffect(() => {
        setContacts(props.contacts_to_request === null ? [] : JSON.parse(props.contacts_to_request));
    }, [props.contacts_to_request]);

    React.useEffect(() => {
        if (props.selectedPositions.includes(props.positionID)) {
            setChecked(true);
        } else {
            setChecked(false);
        }
    }, [props.selectedPositions]);

    React.useEffect(() => {
        if (checked) {
            props.selectPosition(props.positionID);
        } else {
            props.unselectPosition(props.positionID);
        }
    }, [checked]);

    let positionTypeColor = '';
    switch (type) {
        case '1':
            positionTypeColor = 'primary';
            break;
        case '2':
            positionTypeColor = 'secondary';
            break;
        case '3':
            positionTypeColor = 'info';
            break;
        default:
            positionTypeColor = 'info';
            break;
    }

    return (
        <div>
            <Accordion expanded={expand} sx={{ width: '100%', mb: 1, background: props.edited ? '#20c34b16' : '' }}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon onClick={toggleAccordion} />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    sx={{
                        '&:hover': { cursor: 'default' }
                    }}>
                    <Grid container>
                        {props.provision_status !== '' ? (
                            <Grid item xs={12}>
                                <Typography sx={{ fontStyle: 'italic' }}>
                                    **
                                    <>{props.provision_status}</>
                                    **
                                </Typography>
                            </Grid>
                        ) : null}
                        <Grid item xs={1}>
                            <Checkbox checked={checked} onChange={handleChecked} sx={{ p: 0 }} />
                        </Grid>
                        <Grid item xs={2} onClick={toggleAccordion}>
                            <Typography sx={{ fontWeight: 'bold', overflowWrap: 'break-word' }}>
                                {props.pos_nr}
                            </Typography>
                        </Grid>
                        <Grid item xs={4} onClick={toggleAccordion} sx={{ pr: 2 }}>
                            <Typography sx={{ fontWeight: 'bold' }}>{props.outlineTxt}</Typography>
                        </Grid>
                        <Grid item xs={1}>
                            <Typography>{props.amount_type} </Typography>
                        </Grid>
                        <Grid item xs={1}>
                            <Typography>{props.amount}</Typography>
                        </Grid>
                        <Grid item xs={3}>
                            {(props.documentViewType === 'offers' || props.documentState > 2) && !props.editMode ? (
                                type ? (
                                    <SupplierChip
                                        type={type}
                                        allContacts={props.contacts}
                                        contacts={contacts}
                                        positionTypeColor={positionTypeColor}
                                    />
                                ) : null
                            ) : (
                                <SupplierInput
                                    positionID={props.positionID}
                                    type={type}
                                    editMode={props.editMode}
                                    handleTypeChange={handleTypeChange}
                                    allContacts={props.contacts}
                                    allProviders={props.providers}
                                    selectedContacts={contacts}
                                    handleContactChange={handleContactChange}
                                    hasProduct={props.edited}
                                    handleContactAdd={handleContactAdd}
                                />
                            )}
                        </Grid>
                    </Grid>
                </AccordionSummary>
                <AccordionDetails>
                    <Box sx={{ background: theme.palette.neutral[100], p: 2, pb: 1, mt: 1 }}>
                        <Typography sx={{ fontSize: 14, marginTop: '-36px' }} color="text.secondary" gutterBottom>
                            Beschreibung
                        </Typography>
                        <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(props.detailedTxt) }}></div>
                    </Box>
                    {expand && props.documentViewType === 'offers' ? (
                        <Box sx={{ mt: 2, mb: 4 }}>
                            <Products
                                positionID={props.positionID}
                                posNo={props.pos_nr}
                                positionSourceType={props.type}
                                documentID={props.documentID}
                                positionType={type}
                                requested_amount_type={props.amount_type}
                                requested_amount={props.amount}
                            />
                        </Box>
                    ) : null}
                </AccordionDetails>
            </Accordion>
        </div>
    );
}
