import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import {
    AppBar,
    Avatar,
    Box,
    Menu,
    IconButton,
    MenuItem,
    Toolbar,
    Typography,
    Divider,
    ListItemIcon,
    ListItemText
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import LogoutIcon from '@mui/icons-material/Logout';
import AuthService from '../api/services/Auth';
import AuthUtils from '../api/auth/utils.js';
import { useNavigate } from 'react-router-dom';
import AuthVerify from '../api/auth/auth-verify';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SettingsIcon from '@mui/icons-material/Settings';
import { Link } from 'react-router-dom';

const PageNavbarRoot = styled(AppBar)(({ theme }) => ({
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[3]
}));

export const PageNavbar = (props) => {
    const { onSidebarOpen, ...other } = props;
    const [anchorElUser, setAnchorElUser] = useState(null);

    const navigate = useNavigate();
    const handleLogout = () => {
        AuthService.logout();
        navigate('/', { replace: true });
    };
    const user = AuthUtils.getCurrentUser();

    const handleOpenUserMenu = (e) => {
        setAnchorElUser(e.currentTarget);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    const userName = user?.public_name || user?.name || 'Unknown';

    return (
        <>
            <PageNavbarRoot
                sx={{
                    left: { lg: 280 },
                    width: { lg: 'calc(100% - 280px)' }
                }}
                {...other}>
                <Toolbar
                    disableGutters
                    sx={{
                        minHeight: 64,
                        left: 0,
                        px: 2
                    }}>
                    <IconButton
                        onClick={onSidebarOpen}
                        sx={{
                            display: {
                                xs: 'inline-flex',
                                lg: 'none'
                            }
                        }}>
                        <MenuIcon fontSize="small" />
                    </IconButton>
                    <Typography
                        variant="h1"
                        component="div"
                        sx={{ flexGrow: 1, color: 'text.secondary', fontSize: '24px', fontWeight: 'normal' }}>
                        {props.title}
                    </Typography>
                    <Box>
                        <Box
                            onClick={handleOpenUserMenu}
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                flexWrap: 'wrap',
                                cursor: 'pointer',
                                minWidth: '200px'
                            }}>
                            <Divider orientation="vertical" flexItem sx={{ mr: 2 }} />
                            <Avatar sx={{ bgcolor: 'fassbender.green', height: 30, width: 30, fontSize: 16 }}>
                                {userName[0].toUpperCase()}
                            </Avatar>
                            <Typography component="span" sx={{ color: 'text.secondary', ml: 2 }}>
                                {userName}
                            </Typography>
                            <ExpandMoreIcon sx={{ color: 'text.secondary' }} />
                        </Box>
                        <Menu
                            open={Boolean(anchorElUser)}
                            anchorEl={anchorElUser}
                            onClose={handleCloseUserMenu}
                            PaperProps={{
                                sx: {
                                    mt: 1.5,
                                    borderTopLeftRadius: 0,
                                    borderTopRightRadius: 0,
                                    minWidth: '200px'
                                }
                            }}>
                            <MenuItem component={Link} to={'/users/' + user.public_id}>
                                <ListItemIcon>
                                    <SettingsIcon fontSize="small" />
                                </ListItemIcon>
                                <ListItemText>Einstellungen</ListItemText>
                            </MenuItem>
                            <MenuItem onClick={handleLogout}>
                                <ListItemIcon>
                                    <LogoutIcon fontSize="small" />
                                </ListItemIcon>
                                <ListItemText>Ausloggen</ListItemText>
                            </MenuItem>
                        </Menu>
                    </Box>
                    <AuthVerify logOut={handleLogout} />
                </Toolbar>
            </PageNavbarRoot>
        </>
    );
};

PageNavbar.propTypes = {
    onSidebarOpen: PropTypes.func,
    title: PropTypes.string
};
