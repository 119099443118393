import AddIcon from '@mui/icons-material/Add';
import { Button } from '@mui/material';
import Alert from '@mui/material/Alert';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import React, { useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import ContactService from '../api/services/Contact';
import { validateEmail } from '../utils/Validations';
import { CircularLoading } from './CircularLoading';

export default function AddContactDialog(props) {
    const [contactEmail, setContactEmail] = useState('');
    const [open, setOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [inTransaction, setInTransaction] = useState(false);

    const queryClient = useQueryClient();

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setContactEmail('');
        setOpen(false);
        setErrorMessage('');
        setInTransaction(false);
    };

    const contactMutation = useMutation(
        () => {
            return ContactService.put({ email: contactEmail, provider_id: props.providerID });
        },
        {
            onSuccess: (contactAdded) => {
                queryClient.setQueryData(['contacts', props.providerID], (oldData) => {
                    oldData.data.push(contactAdded.data);
                    return oldData;
                });
                handleClose();
            },
            onError: (error) => {
                console.log(error.response);
                if (error.response) {
                    if (error.response.status === 400) {
                        setErrorMessage(error.response.status + ': Validation error: could not process');
                    } else {
                        setErrorMessage(error.response.status + ': ' + error.response.data.message);
                    }
                } else if (error.request) {
                    setErrorMessage('Server not reachable');
                    console.log(error.request);
                } else {
                    setErrorMessage('Undefined error, please contact your admin');
                    console.log('Error', error.message);
                }
            },
            onSettled: () => {
                setInTransaction(false);
            }
        }
    );

    return (
        <div>
            <Button variant="outlined" size="small" onClick={handleClickOpen} startIcon={<AddIcon />}>
                Kontakt hinzufügen
            </Button>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Füge einen neuen Kontakt hinzu</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {errorMessage ? <Alert severity="error">{errorMessage}</Alert> : ''}
                    </DialogContentText>
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="new-contact"
                        label="E-Mail-Adresse"
                        name="new-contact"
                        autoComplete="off"
                        autoFocus
                        error={!validateEmail(contactEmail)}
                        helperText={validateEmail(contactEmail) ? '' : 'Email ungültig!'}
                        type="email"
                        value={contactEmail}
                        onChange={(e) => setContactEmail(e.target.value)}
                    />
                    {inTransaction ? (
                        <>
                            <CircularLoading width="40px" mr="4" /> Kontakt wird hinzugefügt...
                        </>
                    ) : null}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Abbrechen</Button>
                    <Button
                        disabled={inTransaction}
                        onClick={() => {
                            setInTransaction(true);
                            contactMutation.mutate();
                        }}>
                        Kontakt hinzufügen
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
