import AddIcon from '@mui/icons-material/Add';
import { Button } from '@mui/material';
import Alert from '@mui/material/Alert';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import React, { useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import ClientService from '../api/services/Client';
import { CircularLoading } from './CircularLoading';

export function AddClientDialog() {
    const [clientName, setClientName] = useState('');
    const [address, setAddress] = useState('');
    const [open, setOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [inTransaction, setInTransaction] = useState(false);

    const queryClient = useQueryClient();

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setClientName('');
        setAddress('');
        setOpen(false);
        setErrorMessage('');
        setInTransaction(false);
    };

    const clientMutation = useMutation(
        () => {
            return ClientService.put({ name: clientName, address: address });
        },
        {
            onSuccess: (clientAdded) => {
                queryClient.setQueryData(['clients', 0], (oldData) => {
                    oldData.data.clients.push(clientAdded.data);
                    oldData.data.clients.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
                    return oldData;
                });
                handleClose();
            },
            onError: (error) => {
                console.log(error.response);
                if (error.response) {
                    if (error.response.status === 400) {
                        setErrorMessage(error.response.status + ': Validation error: could not process');
                    } else {
                        setErrorMessage(error.response.status + ': ' + error.response.data.message);
                    }
                } else if (error.request) {
                    setErrorMessage('Server not reachable');
                    console.log(error.request);
                } else {
                    setErrorMessage('Undefined error, please contact your admin');
                    console.log('Error', error.message);
                }
            },
            onSettled: () => {
                setInTransaction(false);
            }
        }
    );

    return (
        <span>
            <Button variant="outlined" startIcon={<AddIcon />} onClick={handleClickOpen} sx={{ mr: 1 }}>
                Bauunternehmer hinzufügen
            </Button>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Füge einen neuen Bauunternehmer hinzu</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {errorMessage ? <Alert severity="error">{errorMessage}</Alert> : ''}
                    </DialogContentText>
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="new-client"
                        label="Name des Bauunternehmers"
                        name="new-client"
                        autoComplete="off"
                        autoFocus
                        value={clientName}
                        onChange={(e) => setClientName(e.target.value)}
                    />
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="client-address"
                        label="Adresse des Bauunternehmers"
                        name="client-address"
                        autoComplete="off"
                        autoFocus
                        value={address}
                        multiline
                        minRows={3}
                        onChange={(e) => setAddress(e.target.value)}
                    />
                    {inTransaction ? (
                        <>
                            <CircularLoading width="40px" mr="4" /> Bauunternehmer wird hinzugefügt...
                        </>
                    ) : null}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Abbrechen</Button>
                    <Button
                        disabled={inTransaction}
                        onClick={() => {
                            setInTransaction(true);
                            clientMutation.mutate();
                        }}>
                        Bauunternehmer hinzufügen
                    </Button>
                </DialogActions>
            </Dialog>
        </span>
    );
}
