import { useTheme } from '@emotion/react';
import AddIcon from '@mui/icons-material/Add';
import AllInboxIcon from '@mui/icons-material/AllInbox';
import AltRouteIcon from '@mui/icons-material/AltRoute';
import CancelIcon from '@mui/icons-material/Cancel';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SaveIcon from '@mui/icons-material/Save';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Alert,
    AlertTitle,
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    Checkbox,
    Chip,
    Collapse,
    Divider,
    FormControlLabel,
    Grid,
    IconButton,
    InputAdornment,
    TextField,
    Tooltip,
    Typography
} from '@mui/material';
import { evaluate } from 'mathjs';
import React, { useEffect, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import PositionService from '../api/services/Position.js';
import ProductService from '../api/services/Product.js';
import ProductGroupService from '../api/services/ProductGroup.js';
import { DeleteConfirmation } from '../components/DeleteConfirmation';
import { replacePositions } from '../utils/HierarchyUtils';
import { PdfEventEmitter } from './PdfViewer.js';

function ProductForm(props) {
    const theme = useTheme();

    const [name, setName] = useState(props.name);
    const [description, setDescription] = useState(props.description);
    const [amount, setAmount] = useState(props.amount);
    const [amount_type, setAmountType] = useState(props.amount_type);
    const [incompatibityCheckbox, setIncompatibityCheckbox] = useState(props.incompatibility_warning);
    const [price, setPrice] = useState(props.price);
    const [comment, setComment] = useState(props.comment);
    const [errorMessage, setErrorMessage] = useState('');
    const [focus, setFocus] = useState('product-name');
    const fields = [
        'product-name',
        'product-amount',
        'product-amount-type',
        'product-price',
        'position-description',
        'product-comment'
    ];
    const setters = [setName, setAmount, setAmountType, setPrice, setDescription, setComment];
    const [einstandsPreis, setEinstandspreis] = useState(props.einstands_price);
    const [vkPercentage, setVkpercentage] = useState(props.db_percentage);
    const [vkPreis, setVkpreis] = useState(props.vk_price);
    const [ignoreUnitCheckbox, setIgnoreUnitCheckbox] = useState(props.ignore_requested_unit);
    const [amountFormula, setAmountFormula] = useState(props.amount_formula);
    const [totalPrice, setTotalPrice] = useState(props.total_price);
    const [includeInOffer, setIncludeInOffer] = useState(props.include_in_offer);
    const [formulaToShow, setFormulaToShow] = useState(props.calculation_formula);

    const queryClient = useQueryClient();

    const isAddOnForm = String(props.product_type) === '2' || props.groupID;

    const handleClose = function (new_product_id) {
        setName(props.name);
        setDescription(props.description);
        setAmount(props.amount);
        setAmountType(props.amount_type);
        setPrice(props.price);
        setComment(props.comment);
        setEinstandspreis(props.einstands_price);
        setVkpercentage(props.db_percentage);
        setVkpreis(props.vk_price);
        setTotalPrice(props.total_price);
        setAmountFormula(props.amount_formula);
        setErrorMessage('');
        setIncludeInOffer(props.include_in_offer);
        setIncompatibityCheckbox(props.incompatibility_warning);
        setIgnoreUnitCheckbox(props.ignore_requested_unit);
        props.handleClose(new_product_id);
    };

    useEffect(() => {
        const onPdfSelect = (data) => {
            if (!props.active) {
                return;
            }
            let currentFieldIndex = fields.indexOf(focus);
            let nextField = fields[(currentFieldIndex + 1) % fields.length];
            setters[currentFieldIndex](data);
            setFocus(nextField);
        };
        PdfEventEmitter.subscribe(onPdfSelect);
        return () => {
            PdfEventEmitter.unsubscribe(onPdfSelect);
        };
    }, [props.active, focus]);

    const addProductMutation = useMutation(
        () => {
            return ProductService.put({
                name: name,
                description: description,
                amount: amount,
                price: price,
                amount_type: amount_type,
                comment: comment,
                positionID: props.positionID,
                product_type: props.product_type,
                groupID: props.groupID,
                db_percentage: vkPercentage,
                amount_formula: amountFormula,
                total_price: totalPrice,
                include_in_offer: includeInOffer,
                incompatibility_warning: incompatibityCheckbox,
                ignore_requested_unit: ignoreUnitCheckbox
            });
        },
        {
            onSuccess: (productAdded) => {
                queryClient.invalidateQueries(['product_groups', props.positionID]);
                handleClose(productAdded.data.id);
            },
            onError: (error) => {
                if (error.response) {
                    if (error.response.status === 400) {
                        setErrorMessage(error.response.status + ': ' + error.response.data.message);
                    } else {
                        setErrorMessage(error.response.status + ': ' + error.response.data.message);
                    }
                } else if (error.request) {
                    setErrorMessage('Server not reachable');
                    console.log(error.request);
                } else {
                    setErrorMessage('Undefined error, please contact your admin');
                    console.log('Error', error.message);
                }
                return errorMessage;
            }
        }
    );

    const updateProductMutation = useMutation(
        () => {
            return ProductService.patch({
                name: name,
                description: description,
                amount: amount,
                price: price,
                amount_type: amount_type,
                comment: comment,
                id: props.id,
                positionID: props.positionID,
                productType: props.product_type,
                db_percentage: vkPercentage,
                amount_formula: amountFormula,
                total_price: totalPrice,
                include_in_offer: includeInOffer,
                incompatibility_warning: incompatibityCheckbox,
                ignore_requested_unit: ignoreUnitCheckbox
            });
        },
        {
            onSuccess: () => {
                queryClient.invalidateQueries(['product_groups', props.positionID]);
                handleClose();
            },
            onError: (error) => {
                if (error.response) {
                    if (error.response.status === 400) {
                        setErrorMessage(error.response.status + ': ' + error.response.data.message);
                    } else {
                        setErrorMessage(error.response.status + ': ' + error.response.data.message);
                    }
                } else if (error.request) {
                    setErrorMessage('Server not reachable');
                    console.log(error.request);
                } else {
                    setErrorMessage('Undefined error, please contact your admin');
                    console.log('Error', error.message);
                }
                setTimeout(function () {
                    setErrorMessage('');
                }, 10000);
                return errorMessage;
            }
        }
    );

    const handleSave = function () {
        if (props.id !== '') {
            updateProductMutation.mutate();
        } else {
            addProductMutation.mutate();
        }
    };

    useEffect(() => {
        if (props.positionType === '1') {
            setEinstandspreis(price * 1.01);
        } else {
            setEinstandspreis(price);
        }
    }, [price]);

    useEffect(() => {
        const newVKPreis = (einstandsPreis * (1.0 / (1.0 - vkPercentage / 100))).toFixed(2);
        setVkpreis(newVKPreis);
    }, [vkPercentage, einstandsPreis]);

    useEffect(() => {
        var translated_amount;
        const myAmountFormula = amountFormula.replace(/,/g, '.');
        try {
            setErrorMessage('');
            translated_amount = evaluate(myAmountFormula) * props.requested_amount;
        } catch (error) {
            setErrorMessage('Fehler in der Formel: ' + error.message);
            return;
        }

        let newTotalPrice = 0;

        if (isAddOnForm) {
            newTotalPrice = Number(amount * vkPreis).toFixed(2);
        } else {
            newTotalPrice = Number(translated_amount * vkPreis).toFixed(2);
        }
        setTotalPrice(newTotalPrice);

        if (newTotalPrice !== totalPrice) {
            setFormulaToShow('Abspeichern um die Berechnungsformel anzuzeigen...');
        }
    }, [vkPreis, amountFormula, amount]);

    return (
        <Card
            sx={{
                height: 'auto',
                background: props.index % 2 ? '#f8f8ff' : 'white',
                p: 0
            }}
            variant="outlined">
            <CardHeader
                title={(isAddOnForm ? 'Zusatzprodukt' : 'Produkt') + (props.id ? ' bearbeiten' : ' hinzufügen')}
                sx={{ p: 2, background: theme.palette.fassbender.green, color: 'white' }}
            />
            <Divider />
            <CardContent sx={{ p: 2 }}>
                {errorMessage !== '' ? (
                    <Alert severity="error" sx={{ mb: 2 }}>
                        <AlertTitle>Fehler</AlertTitle>
                        {errorMessage}
                    </Alert>
                ) : null}
                <Box component="form" autoComplete="off">
                    <TextField
                        id="product-name"
                        label="Produktname"
                        value={name}
                        sx={{ mb: 1 }}
                        fullWidth
                        size="small"
                        onFocus={() => setFocus('product-name')}
                        focused={'product-name' === focus}
                        onChange={(e) => setName(e.target.value)}
                    />
                    <TextField
                        id="product-amount"
                        fullWidth
                        sx={{ mb: 1 }}
                        type={'number'}
                        InputProps={{
                            locale: 'de-DE',
                            step: 0.1
                        }}
                        label="Angebotene Menge"
                        value={amount}
                        disabled={ignoreUnitCheckbox}
                        size="small"
                        onFocus={() => setFocus('product-amount')}
                        focused={'product-amount' === focus}
                        onChange={(e) => setAmount(e.target.value)}
                    />
                    <TextField
                        id="product-amount-type"
                        label="Angebotene Einheit"
                        fullWidth
                        sx={{ mb: 1 }}
                        value={amount_type}
                        size="small"
                        onFocus={() => setFocus('product-amount-type')}
                        focused={'product-amount-type' === focus}
                        onChange={(e) => setAmountType(e.target.value)}
                    />
                    <TextField
                        id="product-price"
                        type="number"
                        label="Einkaufspreis pro Einheit"
                        fullWidth
                        sx={{ mb: 1 }}
                        value={price}
                        size="small"
                        onFocus={() => setFocus('product-price')}
                        focused={'product-price' === focus}
                        onChange={(e) => setPrice(e.target.value)}
                        InputProps={{
                            locale: 'de-DE',
                            step: 0.1,
                            startAdornment: <InputAdornment position="start">€</InputAdornment>
                        }}
                    />
                    {props.product_type === 1 && (
                        <TextField
                            id="amount-formula"
                            label="Umrechnung Mengeneinheit"
                            disabled={ignoreUnitCheckbox}
                            fullWidth
                            sx={{ mb: 1 }}
                            value={amountFormula}
                            size="small"
                            onChange={(e) => setAmountFormula(e.target.value)}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">{props.requested_amount_type} =</InputAdornment>
                                ),
                                endAdornment: <InputAdornment position="end">* {amount_type}</InputAdornment>
                            }}
                        />
                    )}
                    {props.type === '1' ? (
                        <TextField
                            id="product-einstandspreis"
                            disabled
                            type="number"
                            label="Einstandspreis"
                            fullWidth
                            sx={{ mb: 1 }}
                            value={einstandsPreis}
                            size="small"
                            InputProps={{
                                locale: 'de-DE',
                                step: 0.1,
                                startAdornment: <InputAdornment position="start">€</InputAdornment>
                            }}
                        />
                    ) : null}
                    <TextField
                        id="product-DB%"
                        label="DB%"
                        type="number"
                        fullWidth
                        sx={{ mb: 1 }}
                        value={vkPercentage}
                        size="small"
                        onChange={(e) => setVkpercentage(e.target.value)}
                        InputProps={{
                            locale: 'de-DE',
                            step: 0.1
                        }}
                    />
                    <TextField
                        disabled
                        id="product-vk-preis"
                        label="VK-Preis"
                        variant="filled"
                        fullWidth
                        sx={{ mb: 1 }}
                        value={vkPreis}
                        size="small"
                        InputProps={{
                            startAdornment: <InputAdornment position="start">€</InputAdornment>,
                            locale: 'de-DE',
                            step: 0.1
                        }}
                    />
                    {props.compute_formula ? (
                        <Box sx={{ mb: 1, color: 'text.secondary', fontSize: '14px' }}>
                            <div dangerouslySetInnerHTML={{ __html: formulaToShow }} />
                        </Box>
                    ) : null}
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={incompatibityCheckbox}
                                onChange={(e) => setIncompatibityCheckbox(e.target.checked)}
                                name="incompatibityCheckbox"
                                color="primary"
                            />
                        }
                        label="Zeige Warnhinweis zu inkompatiblen Einheiten"
                    />
                    {props.product_type == '1' && (
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={ignoreUnitCheckbox}
                                    onChange={(e) => {
                                        if (e.target.checked) {
                                            setAmount(0);
                                            setAmountFormula('1');
                                        }
                                        setIgnoreUnitCheckbox(e.target.checked);
                                    }}
                                    name="ignoreUnitCheckbox"
                                    color="primary"
                                />
                            }
                            label={
                                <>
                                    <span>Angefragte Einheit ignorieren.</span>{' '}
                                    <span style={{ color: 'crimson' }}>
                                        (Produkt wird nicht in GAEB exportiert und nicht für Berechnung des
                                        Gesamtpreises einbezogen!)
                                    </span>
                                </>
                            }
                        />
                    )}
                    <TextField
                        disabled
                        id="product-total-price"
                        label="Gesamtpreis"
                        fullWidth
                        sx={{ mb: 1 }}
                        value={totalPrice}
                        size="small"
                        variant="filled"
                    />
                    <Typography sx={{ fontSize: 14, ml: 1 }} color="text.secondary" gutterBottom>
                        in Angebot aufnehmen
                        <Checkbox
                            default={props.include_in_offer}
                            sx={{ ml: 2 }}
                            checked={includeInOffer}
                            onChange={(e) => setIncludeInOffer(e.target.checked)}
                        />
                    </Typography>
                    <TextField
                        multiline
                        fullWidth
                        rows={3}
                        sx={{ mb: 1 }}
                        id="position-description"
                        label="Produktbeschreibung"
                        value={description}
                        size="small"
                        onFocus={() => setFocus('position-description')}
                        focused={'position-description' === focus}
                        onChange={(e) => setDescription(e.target.value)}
                    />
                    <TextField
                        multiline
                        rows={3}
                        fullWidth
                        id="product-comment"
                        label={'Kommentare'}
                        value={comment}
                        size="small"
                        onFocus={() => setFocus('product-comment')}
                        focused={'product-comment' === focus}
                        onChange={(e) => setComment(e.target.value)}
                    />
                </Box>
            </CardContent>
            <CardActions disableSpacing sx={{ p: 1, m: 0 }}>
                <Button startIcon={<SaveIcon />} onClick={handleSave}>
                    Speichern
                </Button>
                <Button startIcon={<CancelIcon />} onClick={handleClose}>
                    Abbrechen
                </Button>
            </CardActions>
        </Card>
    );
}

ProductForm.defaultProps = {
    name: '',
    description: '',
    amount: '',
    amount_type: '',
    price: '',
    comment: '',
    groupID: '',
    id: '',
    type: '',
    einstands_price: '',
    db_percentage: 2,
    vk_price: '',
    amount_formula: '1',
    total_price: '',
    requested_amount: '',
    requested_amount_type: '',
    include_in_offer: true,
    amount_option: 0,
    incompatibility_warning: false,
    ignore_requested_unit: false
};

function SingleProductView({ data, positionID, index, type, requested_amount, requested_amount_type }) {
    const [editing, setEditing] = useState(false);
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const queryClient = useQueryClient();

    if (editing) {
        return (
            <ProductForm
                {...data}
                positionID={positionID}
                active={editing}
                index={index}
                handleClose={() => setEditing(false)}
                type={type}
                requested_amount={requested_amount}
                requested_amount_type={requested_amount_type}
            />
        );
    }

    return (
        <Card
            sx={{
                background: index % 2 ? '#f8f8ff' : 'white',
                p: 0,
                border: String(data.product_type) === '1' ? '1px solid black' : '',
                borderColor: String(data.product_type) === '1' ? 'fassbender.red' : '',
                backgroundColor: data.include_in_offer ? '' : '#ffeff1',
                position: 'relative',
                overflow: 'visible'
            }}
            variant="outlined">
            {showDeleteConfirmation && (
                <DeleteConfirmation
                    entity={data}
                    onSuccess={() => {
                        queryClient.invalidateQueries(['product_groups', positionID]);
                    }}
                    mutationFunction={() => {
                        return ProductService.delete(data.id);
                    }}
                    confirmationTitle="Produkt löschen?"
                    confirmationMessage={`Produkt "${data.name}" wird gelöscht.`}
                    loadingMessage={'Produkt löschen'}
                    showDeleteConfirmation={showDeleteConfirmation}
                    setShowDeleteConfirmation={setShowDeleteConfirmation}
                    errorMessage={errorMessage}
                    setErrorMessage={setErrorMessage}
                />
            )}
            <Box
                sx={{
                    position: 'absolute',
                    right: '8px',
                    top: '-8px',
                    padding: '2px 5px',
                    background: index % 2 ? '#f8f8ff' : 'white',
                    fontSize: '10px',
                    color: 'text.secondary',
                    border: '1px solid black',
                    borderColor: String(data.product_type) === '1' ? 'fassbender.red' : '#E6E8F0',
                    borderRadius: '3px'
                }}>
                {String(data.product_type) === '1' ? 'Hauptprodukt' : 'Zusatzprodukt'}
            </Box>
            <CardHeader
                title={data.name}
                sx={{ p: 2 }}
                action={
                    data.product_type === 2 ? (
                        <IconButton
                            color="error"
                            aria-label="delete"
                            variant="contained"
                            size="small"
                            onClick={() => setShowDeleteConfirmation(true)}>
                            <DeleteIcon />
                        </IconButton>
                    ) : null
                }
            />
            {data.include_in_offer ? null : (
                <Alert severity="error" sx={{ boxShadow: 1, backgroundColor: '#FEFEFE' }}>
                    <Typography sx={{ ml: 2, fontSize: 14 }} color="text.secondary" gutterBottom>
                        <strong>nicht im Angebot enthalten</strong>
                    </Typography>
                </Alert>
            )}
            <Divider />
            <CardContent sx={{ p: 2 }}>
                <Grid container mb={3}>
                    <Grid item xs={4}>
                        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                            Ang. Einheit
                        </Typography>
                        <Typography>{data.amount_type}</Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                            Ang. Menge
                        </Typography>{' '}
                        <Typography>{String(data.amount).replace('.', ',')}</Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                            Einzelpreis
                        </Typography>{' '}
                        <Typography>{String(data.price).replace('.', ',')}</Typography>
                    </Grid>
                </Grid>
                {data.product_type !== 2 && !data.ignore_requested_unit && (
                    <Grid container mb={3}>
                        <Grid item xs={8}>
                            <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                                Umrechnung Einheiten
                            </Typography>
                            <Typography>
                                {requested_amount_type} = {data.amount_formula} {data.amount_type}
                            </Typography>
                        </Grid>
                    </Grid>
                )}
                <Divider />
                <Grid container mb={2} mt={1}>
                    <Grid item xs={4}>
                        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                            Einst.Preis
                        </Typography>
                        <Typography>{String(data.einstands_price.toFixed(2)).replace('.', ',')} €</Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                            DB%
                        </Typography>{' '}
                        <Typography>{String(data.db_percentage).replace('.', ',')}</Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                            VK-Preis
                        </Typography>{' '}
                        <Typography>{String(data.vk_price.toFixed(2)).replace('.', ',')} €</Typography>
                    </Grid>
                    <Grid item xs={12} sx={{ mt: 2 }}>
                        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                            Gesamtpreis Produkt
                        </Typography>
                        <Box color="text.secondary" sx={{ fontSize: 14, mb: 1 }}>
                            <div dangerouslySetInnerHTML={{ __html: data.compute_formula }} />
                        </Box>
                        <Typography>{String(data.total_price.toFixed(2)).replace('.', ',')} €</Typography>
                    </Grid>
                </Grid>
                <Divider />
                <Box mt={1} mb={1}>
                    <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                        Beschreibung
                    </Typography>
                    <Typography>{data.description}</Typography>
                </Box>
                {data.comment !== '' ? (
                    <Box mt={1}>
                        <Typography mt={2} sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                            Kommentar
                        </Typography>
                        <Typography>{data.comment}</Typography>
                    </Box>
                ) : null}
            </CardContent>
            <Divider />
            <CardActions disableSpacing>
                <Button
                    startIcon={<EditIcon />}
                    aria-label="bearbeiten"
                    onClick={() => setEditing(true)}
                    color="primary">
                    Bearbeiten
                </Button>
            </CardActions>
        </Card>
    );
}

function ProductRow({
    products,
    positionID,
    groupID,
    deleteHandler,
    index,
    xsCols,
    mdCols,
    xlCols,
    type,
    requested_amount,
    requested_amount_type
}) {
    const [addZusatzOpen, setAddZusatzOpen] = useState(false);

    return (
        <>
            <Grid container spacing={1} justifyContent="flex-start" direction="row">
                {products.map(function (product, idx) {
                    return (
                        <Grid item xs={xsCols} md={mdCols} xl={xlCols} key={idx}>
                            <SingleProductView
                                index={index}
                                data={product}
                                deleteHandler={deleteHandler}
                                positionID={positionID}
                                type={type}
                                requested_amount={requested_amount}
                                requested_amount_type={requested_amount_type}
                            />
                        </Grid>
                    );
                })}
                <Grid item xs={xsCols} md={mdCols} xl={xlCols} key={products.length}>
                    <Collapse in={addZusatzOpen}>
                        <ProductForm
                            index={index}
                            active={addZusatzOpen}
                            handleClose={() => setAddZusatzOpen(false)}
                            product_type={2}
                            positionID={positionID}
                            groupID={groupID}
                            type={type}
                            requested_amount={requested_amount}
                            requested_amount_type={requested_amount_type}
                        />
                    </Collapse>
                </Grid>
            </Grid>
            <Box sx={{ mt: 2 }}>
                {!addZusatzOpen ? (
                    <Button
                        startIcon={<AddIcon />}
                        onClick={() => setAddZusatzOpen(true)}
                        variant="contained"
                        size="small"
                        sx={{ background: 'fassbender.green' }}>
                        Zusatzprodukt hinzufügen
                    </Button>
                ) : null}
            </Box>
        </>
    );
}

function Products({
    positionID,
    posNo,
    documentID,
    positionSourceType,
    numCols,
    positionType,
    requested_amount,
    requested_amount_type
}) {
    const [accordionExpanded, setAccordionExpanded] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const [addProductOpen, setAddProductOpen] = useState(false);

    const queryClient = useQueryClient();

    const sortProductGroupData = async (positionID) => {
        const response = await PositionService.get(positionID);
        await queryClient.setQueryData(['positions', documentID], (oldData) =>
            replacePositions(oldData, response.data)
        );
        let data = response.data.product_groups.sort((a, b) => b.is_main - a.is_main);
        return {
            ...response,
            data: Object.values(data),
            min_price: String(response.data.min_price.toFixed(2)).replace('.', ','),
            max_price: String(response.data.max_price.toFixed(2)).replace('.', ','),
            standard_price: String(response.data.standard_price.toFixed(2)).replace('.', ','),
            num_alternative: Math.max(0, response.data.product_groups.length - 1)
        };
    };

    const { isError, isLoading, error, data } = useQuery(
        ['product_groups', positionID],
        () => sortProductGroupData(positionID),
        {
            keepPreviousData: true,
            refetchOnWindowFocus: false,
            staleTime: 10000
        }
    );

    const markAsMainProductGroupMutation = useMutation(
        (productGroup_id) => {
            return ProductGroupService.patch(productGroup_id);
        },
        {
            onSuccess: () => {
                queryClient.invalidateQueries(['product_groups', positionID]);
            },
            onError: (error) => {
                if (error.response) {
                    if (error.response.status === 400) {
                        setErrorMessage(error.response.status + ': Validation error: could not process');
                    } else {
                        setErrorMessage(error.response.status + ': ' + error.response.data.message);
                    }
                } else if (error.request) {
                    setErrorMessage('Server not reachable');
                    console.log(error.request);
                } else {
                    setErrorMessage('Undefined error, please contact your admin');
                    console.log('Error', error.message);
                }
                setTimeout(function () {
                    setErrorMessage('');
                }, 10000);
            }
        }
    );

    const deleteProductMutation = useMutation(
        (productID) => {
            return ProductService.delete(productID);
        },
        {
            onSuccess: () => {
                queryClient.invalidateQueries(['product_groups', positionID]);
                //TODO: update query data
            },
            onError: (error) => {
                if (error.response) {
                    if (error.response.status === 400) {
                        setErrorMessage(error.response.status + ': Validation error: could not process');
                    } else {
                        setErrorMessage(error.response.status + ': ' + error.response.data.message);
                    }
                } else if (error.request) {
                    setErrorMessage('Server not reachable');
                    console.log(error.request);
                } else {
                    setErrorMessage('Undefined error, please contact your admin');
                    console.log('Error', error.message);
                }
                setTimeout(function () {
                    setErrorMessage('');
                }, 10000);
            }
        }
    );
    const deleteRow = function (productID) {
        deleteProductMutation.mutate(productID);
    };

    // hardcoded for now, since we only have 4 or 1 column(s)
    const xsCols = numCols === 4 ? 6 : 12;
    const mdCols = numCols === 4 ? 4 : 12;
    const xlCols = numCols === 4 ? 3 : 12;

    return (
        <>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                <Typography variant="h6">Ausgewählte Produkte</Typography>
                {positionSourceType === '1' ? (
                    <Button
                        startIcon={<AllInboxIcon />}
                        href={`/angebote/${documentID}/${positionID}?isRelative=false`}
                        variant="contained"
                        size="small"
                        sx={{ ml: 1 }}>
                        Aus Anworten übernehmen
                    </Button>
                ) : null}{' '}
                {data &&
                data.hasOwnProperty('standard_price') &&
                data.hasOwnProperty('min_price') &&
                data.hasOwnProperty('max_price') &&
                data.hasOwnProperty('num_alternative') ? (
                    <>
                        <Chip
                            icon={<CreditCardIcon />}
                            label={
                                data.standard_price + '€ (min.: ' + data.min_price + '€, max.: ' + data.max_price + '€)'
                            }
                            sx={{ ml: 2 }}
                            variant="outlined"
                            color="primary"
                        />
                        <Chip
                            icon={<AltRouteIcon />}
                            sx={{ ml: 1 }}
                            variant="outlined"
                            color="primary"
                            label={
                                data.num_alternative + ' Alternativprodukt' + (data.num_alternative === 1 ? '' : 'e')
                            }
                        />
                    </>
                ) : null}
            </Box>
            <Divider />
            <Box>
                {errorMessage !== '' ? (
                    <Alert severity="error">
                        <AlertTitle>Fehler</AlertTitle>
                        {errorMessage}
                    </Alert>
                ) : null}
                {isLoading ? (
                    <div>Loading...</div>
                ) : isError ? (
                    <div>{error.message}</div>
                ) : Object.keys(data.data).length == 0 ? (
                    <Box sx={{ mt: 2, mb: 2 }}>Bisher keine Produkte zugewiesen</Box>
                ) : (
                    <>
                        {data.data.map(function (productGroup, idx) {
                            const num_add_on = productGroup.products.filter(
                                (product) => product.product_type === 2 && product.include_in_offer
                            ).length;
                            return (
                                <Accordion
                                    key={productGroup.id}
                                    disableGutters
                                    expanded={accordionExpanded === productGroup.id}
                                    sx={{
                                        width: '100%',
                                        background: idx % 2 ? 'white' : '#f8f8ff'
                                    }}>
                                    <AccordionSummary
                                        expandIcon={
                                            <ExpandMoreIcon
                                                onClick={() =>
                                                    setAccordionExpanded(
                                                        accordionExpanded === productGroup.id ? '' : productGroup.id
                                                    )
                                                }
                                            />
                                        }
                                        sx={{ background: 'fassbender.green' }}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header">
                                        {showDeleteConfirmation && (
                                            <DeleteConfirmation
                                                entity={data}
                                                onSuccess={() => {
                                                    queryClient.invalidateQueries(['product_groups', positionID]);
                                                }}
                                                mutationFunction={() => {
                                                    const productToDelete = productGroup.products.find(
                                                        (product) => product.group_id === productGroup.id
                                                    );
                                                    return ProductService.delete(productToDelete.id);
                                                }}
                                                confirmationTitle={`Produkte und Zusatzprodukte (${
                                                    productGroup.products.length - 1
                                                }) Löschen?`}
                                                confirmationMessage={`Produkte für Pos. "${posNo}" werden gelöscht.`}
                                                loadingMessage={'Produkt löschen'}
                                                showDeleteConfirmation={showDeleteConfirmation}
                                                setShowDeleteConfirmation={setShowDeleteConfirmation}
                                                errorMessage={errorMessage}
                                                setErrorMessage={setErrorMessage}
                                            />
                                        )}
                                        <Grid container>
                                            <Grid
                                                item
                                                sx={{ display: 'flex', alignItems: 'center' }}
                                                xs={8}
                                                onClick={() =>
                                                    setAccordionExpanded(
                                                        accordionExpanded === productGroup.id ? '' : productGroup.id
                                                    )
                                                }>
                                                {productGroup.is_main ? (
                                                    <Tooltip title="Angeboten">
                                                        <Box
                                                            sx={{
                                                                width: '15px',
                                                                height: '15px',
                                                                minWidth: '15px',
                                                                backgroundColor: 'fassbender.green',
                                                                borderRadius: '50%',
                                                                display: 'inline-block',
                                                                mr: 1
                                                            }}
                                                        />
                                                    </Tooltip>
                                                ) : (
                                                    <Typography
                                                        variant={'h6'}
                                                        sx={{
                                                            display: 'inline-block',
                                                            fontStyle: 'italic',
                                                            fontWeight: 'normal',
                                                            mr: 1
                                                        }}>
                                                        Alternative:
                                                    </Typography>
                                                )}
                                                <Typography variant={'h6'} sx={{ display: 'inline-block' }}>
                                                    {productGroup.group_name}
                                                </Typography>
                                                <Typography component="span" sx={{ ml: 1 }}>
                                                    ({num_add_on} Zusatzprodukt
                                                    {num_add_on === 1 ? '' : 'e'})
                                                </Typography>
                                                <Typography sx={{ color: 'text.secondary', fontSize: '10px', ml: 1 }}>
                                                    Gesamtpreis:{' '}
                                                    {String(productGroup.min_price.toFixed(2)).replace('.', ',')}€,
                                                    Max.: {String(productGroup.max_price.toFixed(2)).replace('.', ',')}€
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={4} sx={{ textAlign: 'right' }}>
                                                {productGroup.is_main ? (
                                                    <></>
                                                ) : (
                                                    <Button
                                                        onClick={() =>
                                                            markAsMainProductGroupMutation.mutate(productGroup.id)
                                                        }>
                                                        Als angebotenes Produkt wählen
                                                    </Button>
                                                )}
                                                <Tooltip
                                                    title={`Produkt und Zusatzprodukte (${
                                                        productGroup.products.length - 1
                                                    }) löschen`}>
                                                    <IconButton
                                                        color="error"
                                                        variant="contained"
                                                        aria-label="delete"
                                                        onClick={() => {
                                                            setShowDeleteConfirmation(true);
                                                        }}>
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </Tooltip>
                                            </Grid>
                                        </Grid>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <div key={idx} style={{ width: '100%' }}>
                                            <Box>
                                                <ProductRow
                                                    positionID={positionID}
                                                    products={productGroup.products}
                                                    deleteHandler={deleteRow}
                                                    groupID={productGroup.id}
                                                    index={idx}
                                                    xsCols={xsCols}
                                                    mdCols={mdCols}
                                                    xlCols={xlCols}
                                                    positionType={positionType}
                                                    requested_amount={requested_amount}
                                                    requested_amount_type={requested_amount_type}
                                                />
                                            </Box>
                                        </div>
                                    </AccordionDetails>
                                </Accordion>
                            );
                        })}
                    </>
                )}
                {!addProductOpen ? (
                    <Button
                        startIcon={<AddIcon />}
                        onClick={() => setAddProductOpen(true)}
                        variant="contained"
                        size="small">
                        Produkt hinzufügen
                    </Button>
                ) : null}
                <Box>
                    <Collapse in={addProductOpen}>
                        <Grid container>
                            <Grid item xs={xsCols} md={mdCols} xl={xlCols}>
                                <ProductForm
                                    handleClose={(created_id) => {
                                        setAddProductOpen(false);
                                        setAccordionExpanded(created_id);
                                    }}
                                    active={addProductOpen}
                                    product_type={1}
                                    positionID={positionID}
                                    positionType={positionType}
                                    requested_amount={requested_amount}
                                    requested_amount_type={requested_amount_type}
                                />
                            </Grid>
                        </Grid>
                    </Collapse>
                </Box>
            </Box>
        </>
    );
}

Products.defaultProps = {
    numCols: 4
};

export default Products;
