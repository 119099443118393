import DeleteIcon from '@mui/icons-material/Delete';
import EmailIcon from '@mui/icons-material/Email';
import { Alert, Box, Button, Grid, IconButton } from '@mui/material';
import React, { useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import ContactService from '../api/services/Contact';
import { DeleteConfirmation } from '../components/DeleteConfirmation';

function ContactBox({ contact, providerID }) {
    const [errorMessage, setErrorMessage] = useState('');
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

    const queryClient = useQueryClient();

    if (errorMessage !== '') {
        return <Alert severity="error">{errorMessage}</Alert>;
    }

    return (
        <Box sx={{ display: 'inline-block', m: 1 }}>
            {showDeleteConfirmation && (
                <DeleteConfirmation
                    entity={contact}
                    onSuccess={(response) => {
                        queryClient.setQueryData(['contacts', providerID], (oldData) => {
                            const deletedIdx = oldData.data
                                .map((val) => {
                                    return val.id;
                                })
                                .indexOf(parseInt(response.data.contactID));
                            if (deletedIdx != -1) {
                                oldData.data.splice(deletedIdx, 1);
                                return oldData;
                            } else {
                                queryClient.invalidateQueries('contacts', providerID);
                                return oldData;
                            }
                        });
                    }}
                    mutationFunction={() => {
                        return ContactService.delete(contact.id);
                    }}
                    confirmationTitle="Kontakt löschen?"
                    confirmationMessage={`Kontakt "${contact.email}" wird gelöscht`}
                    loadingMessage={'Kontakt löschen'}
                    showDeleteConfirmation={showDeleteConfirmation}
                    setShowDeleteConfirmation={setShowDeleteConfirmation}
                    errorMessage={errorMessage}
                    setErrorMessage={setErrorMessage}
                />
            )}
            <Box
                sx={{
                    p: 0,
                    borderTopLeftRadius: '5px',
                    borderBottomLeftRadius: '5px',
                    display: 'inline-block',
                    border: 1,
                    borderRight: 0,
                    borderColor: 'primary.light'
                }}>
                <Button size="small" variant="text" startIcon={<EmailIcon />}>
                    {contact.email}
                </Button>
            </Box>
            <Box
                sx={{
                    p: 0,
                    display: 'inline-block',
                    borderTopRightRadius: '5px',
                    borderBottomRightRadius: '5px',
                    border: 1,
                    borderColor: 'error.dark',
                    background: 'color'
                }}
                bgcolor="error.light">
                <IconButton
                    sx={{ marginTop: '3px' }}
                    size="small"
                    onClick={() => {
                        setShowDeleteConfirmation(true);
                    }}>
                    <DeleteIcon style={{ color: 'white' }} />
                </IconButton>
            </Box>
        </Box>
    );
}

export default function ProviderContacts(props) {
    const { isLoading, isError, error, data } = useQuery(
        ['contacts', props.providerID],
        () => ContactService.getContactsForProvider(props.providerID),
        {
            keepPreviousData: true,
            refetchOnWindowFocus: false,
            staleTime: 10000
        }
    );

    if (isLoading) {
        return <Box>Loading...</Box>;
    }

    if (isError) {
        return (
            <Box>
                <Alert severity="error">{error}</Alert>
            </Box>
        );
    }

    return (
        <Grid item md={8}>
            {data.data.length ? (
                data.data.map(function (contact) {
                    return <ContactBox contact={contact} key={contact.id} providerID={props.providerID} />;
                })
            ) : (
                <></>
            )}
        </Grid>
    );
}
