import React, { useState } from 'react';
import DocumentService from '../api/services/Document';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import { Button, TextField, Autocomplete } from '@mui/material';
import Alert from '@mui/material/Alert';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useQueryClient, useQuery } from 'react-query';
import InputLabel from '@mui/material/InputLabel';
import { Box } from '@mui/system';
import { CircularLoading } from './CircularLoading';
import ClientService from '../api/services/Client';

export default function ImportDialog() {
    const [selectedFile, setSelectedFile] = useState();
    const [open, setOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [isLoading, setLoading] = useState(false);
    const [dateValue, setDatevalue] = useState(new Date().toISOString().split('T')[0]);
    const [selectedClient, setSelectedClient] = useState('');

    const queryClient = useQueryClient();

    const {
        isError: isErrorClients,
        isLoading: isLoadingClients,
        error: errorClients,
        data: dataClients
    } = useQuery(['clientData'], async () => await ClientService.getMultiple(-1), {
        keepPreviousData: true,
        refetchOnWindowFocus: false,
        staleTime: 10000
    });

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setSelectedFile(null);
        setSelectedClient('');
        setOpen(false);
        setErrorMessage(null);
    };

    const handleSubmission = () => {
        const formData = new FormData();
        formData.append('abgabe_termin', dateValue);

        if (selectedFile === undefined || selectedFile === null || selectedFile === '' || selectedFile === false) {
            setErrorMessage('Fehler: Es muss eine Datei ausgewählt werden.');
            return;
        }
        formData.append('file', selectedFile);

        if (
            selectedClient === undefined ||
            selectedClient === null ||
            selectedClient === '' ||
            selectedClient === false
        ) {
            setErrorMessage(
                'Fehler: Es muss ein Bauunternehmer ausgewählt werden. Wenn der Bauunternehmer noch nicht existiert, ' +
                    'füge ihn unter "Bauunternehmer" hinzu.'
            );
            return;
        }
        formData.append('client_id', selectedClient.id);

        setLoading(true);
        setErrorMessage('');

        // TODO: catch exceptions, display meaningful status
        return DocumentService.put(formData)
            .then((response) => {
                let document_id = response.data.document_id;
                setLoading(false);

                // we invalidate all document query results since we do not know how the added document changed order of
                // existing documents
                queryClient.invalidateQueries('documents');
                handleClose();

                return document_id;
            })
            .catch((error) => {
                setLoading(false);
                if (error.response) {
                    if (error.response.status === 400) {
                        setErrorMessage(error.response.status + ': Validation error: could not process');
                    } else {
                        setErrorMessage(error.response.status + ': The server can not process this request');
                    }
                } else if (error.request) {
                    setErrorMessage('Server not reachable');
                    console.log(error.request);
                } else {
                    setErrorMessage('Undefined error, please contact your admin');
                    console.log('Error', error.message);
                }
            });
    };

    if (isErrorClients) {
        setErrorMessage(
            'Fehler beim Laden der Bauunternehmerdaten: ' +
                errorClients.response.status +
                ': ' +
                errorClients.response.data.message
        );
    }

    return (
        <div>
            <Button variant="contained" size="small" onClick={handleClickOpen} startIcon={<NoteAddIcon />}>
                LV hinzufügen
            </Button>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>LV hinzufügen</DialogTitle>
                <DialogContent>
                    {errorMessage ? <Alert severity="error">{errorMessage}</Alert> : ''}
                    <DialogContentText>Lade eine GAEB-Datei hoch um ein Angebot zu erstellen</DialogContentText>
                    <div>
                        <form>
                            <TextField
                                id="date"
                                label="Abgabe-Termin"
                                type="date"
                                defaultValue={dateValue}
                                onChange={(e) => setDatevalue(e.target.value)}
                                InputLabelProps={{
                                    shrink: true
                                }}
                                sx={{ mt: 2, mb: 2 }}
                            />
                        </form>
                    </div>

                    <InputLabel id={'file-select-label'} sx={{ mt: 2 }}>
                        Datei-Upload
                    </InputLabel>
                    <input
                        accept=".X86, .X83, .X93, .P83, .D83" // Various GAEB formats we want to support
                        style={{ display: 'none' }}
                        id="raised-button-file"
                        onChange={(event) => setSelectedFile(event.target.files[0])}
                        multiple
                        type="file"
                        required
                    />
                    <label htmlFor="raised-button-file">
                        <Button variant="contained" component="span">
                            Auswählen
                        </Button>
                        {selectedFile ? <Button>Ausgewählt: {selectedFile.name}</Button> : ''}
                    </label>
                    {isLoadingClients ? (
                        <Box>
                            <CircularLoading width="40px" mr={2} /> Bauunternehmer laden...
                        </Box>
                    ) : (
                        <Autocomplete
                            id={'client-id'}
                            options={dataClients?.data}
                            getOptionLabel={(option) => String(option.name)}
                            onChange={(_event, newValue) => {
                                setSelectedClient(newValue);
                            }}
                            sx={{ mt: 2, ml: 0, mr: 1 }}
                            size="small"
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    required
                                    placeholder={'Suche Bauunternehmer'}
                                    label="Bauunternehmer wählen"
                                    variant="standard"
                                />
                            )}
                        />
                    )}
                </DialogContent>

                <DialogActions>
                    {isLoading ? (
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                position: 'absolute',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                                color: 'neutral.700',
                                mt: 2,
                                mb: 2
                            }}>
                            <CircularLoading width="2rem" mr={2} /> Importieren...
                        </Box>
                    ) : (
                        <>
                            <Button onClick={handleClose}>Abbrechen</Button>
                            <Button onClick={handleSubmission}>Datei hochladen</Button>
                        </>
                    )}
                </DialogActions>
            </Dialog>
        </div>
    );
}
