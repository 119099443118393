import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import SearchIcon from '@mui/icons-material/Search';
import {
    Alert,
    Box,
    Button,
    Container,
    Divider,
    Grid,
    InputAdornment,
    TablePagination,
    TextField,
    Typography
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { CircularLoading } from '../components/CircularLoading';
import { DeleteConfirmation } from '../components/DeleteConfirmation';
import { PageLayout } from '../components/PageLayout';
import { useDebounce } from '../utils/CustomHooks';
// import ManageHistoryIcon from '@mui/icons-material/ManageHistory';
import { FormControl, FormLabel } from '@mui/material';
import 'moment/locale/de';
import { useMutation } from 'react-query';
import ClientService from '../api/services/Client';
import { AddClientDialog } from '../components/AddClientDialog';

function ClientRow({ client, currentPage }) {
    const [errorMessage, setErrorMessage] = useState('');
    const [editing, setEditing] = useState(false);
    const [clientName, setClientName] = useState(client.name);
    const [clientAddress, setClientAddress] = useState(client.address);
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

    useEffect(() => {
        setClientName(client.name);
        setClientAddress(client.address);
    }, [client]);

    const queryClient = useQueryClient();
    const updateClientMutation = useMutation(
        () => {
            return ClientService.patch({
                id: client.id,
                name: clientName,
                address: clientAddress
            });
        },
        {
            onSuccess: (response) => {
                queryClient.setQueryData(['clients', currentPage], (oldData) => {
                    const groupIndexToUpdate = oldData.data.clients.findIndex((client) => {
                        return client.id === response.data.id;
                    });
                    oldData.data.clients[groupIndexToUpdate].name = response.data.name;
                    return oldData;
                });
                setEditing(false);
            },
            onError: (error) => {
                if (error.response) {
                    if (error.response.status === 400) {
                        setErrorMessage(error.response.status + ': Validation error: could not process');
                    } else {
                        setErrorMessage(error.response.status + ': ' + error.response.data.message);
                    }
                } else if (error.request) {
                    setErrorMessage('Server not reachable');
                    console.log(error.request);
                } else {
                    setErrorMessage('Undefined error, please contact your admin');
                    console.log('Error', error.message);
                }
                setTimeout(function () {
                    setErrorMessage('');
                }, 10000);
                return errorMessage;
            }
        }
    );

    const handleSaveButton = (client_id) => {
        if (clientName !== '') {
            updateClientMutation.mutate(client_id);
        } else {
            setErrorMessage('FEHLER: Der Name des Bauunternehmers muss angegeben werden!');
        }
    };

    if (errorMessage !== '') {
        return <Alert severity="error">{errorMessage}</Alert>;
    }

    return (
        <>
            {showDeleteConfirmation && (
                <DeleteConfirmation
                    entity={client}
                    onSuccess={() => {
                        queryClient.invalidateQueries('clients');
                    }}
                    mutationFunction={() => {
                        return ClientService.delete(client.id);
                    }}
                    confirmationTitle="Bauunternehmer löschen?"
                    confirmationMessage={`Bauunternehmer "${client.name}" wird gelöscht`}
                    loadingMessage={'Bauunternehmer löschen'}
                    showDeleteConfirmation={showDeleteConfirmation}
                    setShowDeleteConfirmation={setShowDeleteConfirmation}
                    errorMessage={errorMessage}
                    setErrorMessage={setErrorMessage}
                />
            )}
            <Grid container key={client.id}>
                <Grid item xs={12} sx={{ mt: 2, mb: 2 }}>
                    <Divider />
                </Grid>
                <Grid item md={3}>
                    {editing ? (
                        <FormControl component="fieldset" sx={{ display: 'flex', justifyContent: 'space-between' }}>
                            <FormLabel component="legend" sx={{ mb: 1 }}>
                                Daten
                            </FormLabel>
                            <TextField
                                id="outlined-basic"
                                defaultValue={clientName}
                                label="Name des Bauunternehmers"
                                onChange={(e) => setClientName(e.target.value)}
                            />
                            <TextField
                                id="address"
                                defaultValue={clientAddress}
                                onChange={(e) => setClientAddress(e.target.value)}
                                label="Anschrift"
                                multiline
                                minRows={3}
                            />
                        </FormControl>
                    ) : (
                        <>
                            {' '}
                            <Typography variant="h5">{clientName}</Typography>
                            <Typography variant="body1" color="text.secondary" sx={{ whiteSpace: 'pre-line' }}>
                                {clientAddress}
                            </Typography>
                        </>
                    )}
                    {editing ? (
                        <Button
                            variant="outlined"
                            size="small"
                            color="info"
                            startIcon={<SaveIcon fontSize="small" />}
                            onClick={() => {
                                handleSaveButton(client.id);
                            }}
                            sx={{ mt: 2 }}>
                            Speichern
                        </Button>
                    ) : (
                        <Button
                            variant="outlined"
                            size="small"
                            color="info"
                            startIcon={<EditIcon fontSize="small" />}
                            onClick={() => setEditing(true)}
                            sx={{ mt: 2 }}>
                            Bearbeiten
                        </Button>
                    )}
                    <Button
                        variant="outlined"
                        size="small"
                        color="error"
                        startIcon={<DeleteIcon fontSize="small" />}
                        onClick={() => {
                            setShowDeleteConfirmation(true);
                        }}
                        sx={{ mt: 2 }}>
                        Löschen
                    </Button>
                </Grid>
            </Grid>
        </>
    );
}

export default function ClientsPage() {
    const [page, setPage] = useState(0);
    const [filter, setFilter] = useState('');
    const debouncedFilter = useDebounce(filter, 500);

    const updateFilter = (newFilter) => {
        setFilter(newFilter);
        setPage(0);
    };

    const { isLoading, isError, error, data } = useQuery(
        ['clients', page, debouncedFilter],
        () => {
            if (debouncedFilter === '') {
                return ClientService.getMultiple(page);
            } else {
                return ClientService.search(debouncedFilter, page);
            }
        },
        {
            keepPreviousData: true,
            refetchOnWindowFocus: false,
            staleTime: 10000
        }
    );

    return (
        <PageLayout title="Bauunternehmer bearbeiten">
            <Box
                component="main"
                sx={{
                    flexGrow: 1,
                    py: 8
                }}>
                <Container maxWidth={false}>
                    <Grid container>
                        <Grid item md={4} sx={{ justifyContent: 'left', display: 'flex' }}>
                            <TextField
                                id="outlined-basic"
                                defaultValue={filter}
                                label="Suchen"
                                onChange={(e) => updateFilter(e.target.value)}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <SearchIcon />
                                        </InputAdornment>
                                    )
                                }}
                            />
                            {!isLoading && !isError && data.data.clients.length ? (
                                <TablePagination
                                    component="div"
                                    count={data.data.pagination.num_total}
                                    page={page}
                                    rowsPerPage={data.data.pagination.num_rows_per_page}
                                    rowsPerPageOptions={[]}
                                    onPageChange={(event, newPage) => setPage(newPage)}
                                />
                            ) : (
                                <></>
                            )}
                        </Grid>
                        <Grid item md={8} sx={{ textAlign: 'right' }}>
                            <AddClientDialog />
                        </Grid>
                    </Grid>
                    <Box sx={{ mt: 3 }}>
                        <div>
                            {isLoading ? (
                                <CircularLoading variant="absolute" />
                            ) : isError ? (
                                <Alert severity="error">{error.message}</Alert>
                            ) : data.data.clients.length ? (
                                <>
                                    {data.data.clients.map(function (client) {
                                        return <ClientRow client={client} key={client.id} currentPage={page} />;
                                    })}
                                    <TablePagination
                                        component="div"
                                        count={data.data.pagination.num_total}
                                        page={page}
                                        rowsPerPage={data.data.pagination.num_rows_per_page}
                                        rowsPerPageOptions={[]}
                                        onPageChange={(event, newPage) => setPage(newPage)}
                                    />
                                </>
                            ) : (
                                <div>
                                    <p>Keine Bauunternehmer angelegt</p>
                                </div>
                            )}
                        </div>
                    </Box>
                </Container>
            </Box>
        </PageLayout>
    );
}
